<template>
  <div>
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
    </base-header>

    <div class="container-fluid mt--7">
      <!--Tables-->
      <div class="row mt-5">
        <div class="col-xl-12 mb-5 mb-xl-0">
          <div class="card">
            <div class="card-header border-0">
              <div class="row align-items-center">
                <div class="col">
                  <h3 class="mb-0">자동화연동온실</h3>
                </div>
              </div>
            </div>

            <div class="table-responsive">
              <table class="table text-center align-middle">
                <thead class="thead-light">
                  <tr>
                    <th rowspan="2"></th>
                    <th colspan="2">1동</th>
                    <th colspan="2">2동</th>
                    <th colspan="2">3동</th>
                  </tr>
                  <tr>
                    <th>스프링쿨러</th>
                    <th>측면분수호수</th>
                    <th>스프링쿨러</th>
                    <th>측면분수호수</th>
                    <th>스프링쿨러</th>
                    <th>측면분수호수</th>
                  </tr>
                </thead>
                <tbody class="list">
                  <tr>
                    <th>관수량</th>
                    <td>{{ water.Area1Flow }}</td>
                    <td>{{ water.Area2Flow }}</td>
                    <td>{{ water.Area3Flow }}</td>
                    <td>{{ water.Area4Flow }}</td>
                    <td>{{ water.Area5Flow }}</td>
                    <td>{{ water.Area6Flow }}</td>
                  </tr>
                  <tr>
                    <th>EC</th>
                    <td>{{ water.Area1Ec }}</td>
                    <td>{{ water.Area2Ec }}</td>
                    <td>{{ water.Area3Ec }}</td>
                    <td>{{ water.Area4Ec }}</td>
                    <td>{{ water.Area5Ec }}</td>
                    <td>{{ water.Area6Ec }}</td>
                  </tr>
                  <tr>
                    <th>PH</th>
                    <td>{{ water.Area1Ph }}</td>
                    <td>{{ water.Area2Ph }}</td>
                    <td>{{ water.Area3Ph }}</td>
                    <td>{{ water.Area4Ph }}</td>
                    <td>{{ water.Area5Ph }}</td>
                    <td>{{ water.Area6Ph }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-5">
        <div class="col-xl-12 mb-5 mb-xl-0">
          <div class="card">
            <div class="card-header border-0">
              <div class="row align-items-center">
                <div class="col">
                  <h3 class="mb-0">야외생육시설</h3>
                </div>
              </div>
            </div>

            <div class="table-responsive">
              <table class="table text-center align-middle">
                <thead class="thead-light">
                  <tr>
                    <th rowspan="2"></th>
                    <th colspan="2">1동</th>
                    <th colspan="2">2동</th>
                    <th colspan="2">3동</th>
                  </tr>
                  <tr>
                    <th>스프링쿨러</th>
                    <th>측면분수호수</th>
                    <th>스프링쿨러</th>
                    <th>측면분수호수</th>
                    <th>스프링쿨러</th>
                    <th>측면분수호수</th>
                  </tr>
                </thead>
                <tbody class="list">
                  <tr>
                    <th>관수량</th>
                    <td>{{ water.Area7Flow }}</td>
                    <td>{{ water.Area8Flow }}</td>
                    <td>{{ water.Area9Flow }}</td>
                    <td>{{ water.Area10Flow }}</td>
                    <td>{{ water.Area11Flow }}</td>
                    <td>{{ water.Area12Flow }}</td>
                  </tr>
                  <tr>
                    <th>EC</th>
                    <td>{{ water.Area7Ec }}</td>
                    <td>{{ water.Area8Ec }}</td>
                    <td>{{ water.Area9Ec }}</td>
                    <td>{{ water.Area10Ec }}</td>
                    <td>{{ water.Area11Ec }}</td>
                    <td>{{ water.Area12Ec }}</td>
                  </tr>
                  <tr>
                    <th>PH</th>
                    <td>{{ water.Area7Ph }}</td>
                    <td>{{ water.Area8Ph }}</td>
                    <td>{{ water.Area9Ph }}</td>
                    <td>{{ water.Area10Ph }}</td>
                    <td>{{ water.Area11Ph }}</td>
                    <td>{{ water.Area12Ph }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-5">
        <div class="col-xl-12 mb-5 mb-xl-0">
          <div class="card">
            <div class="card-header border-0">
              <div class="row align-items-center">
                <div class="col">
                  <h3 class="mb-0">양액설비현재값</h3>
                </div>
              </div>
            </div>

            <div class="table-responsive">
              <table class="table text-center align-middle">
                <thead class="thead-light">
                  <tr>
                    <th colspan="3">양액</th>
                    <th colspan="2">EC</th>
                    <th colspan="2">PH</th>
                  </tr>
                  <tr>
                    <th>일사량</th>
                    <th>EC</th>
                    <th>PH</th>
                    <th>설정값 상한</th>
                    <th>설정값 하한</th>
                    <th>설정값 상한</th>
                    <th>설정값 하한</th>
                  </tr>
                </thead>
                <tbody class="list">
                  <tr>
                    <td>{{ water.WaterLux }}</td>
                    <td>{{ water.WaterEc }}</td>
                    <td>{{ water.WaterPh }}</td>
                    <td>{{ water.EcHiLimit }}</td>
                    <td>{{ water.EcLowLimit }}</td>
                    <td>{{ water.PhHiLimit }}</td>
                    <td>{{ water.PhLowLimit }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <!--End tables-->
    </div>
  </div>
</template>
<script>
import DataService from "@/services/data.service";

export default {
  components: {},
  data() {
    return {
      water: {},
    };
  },
  mounted() {
    DataService.getWater().then(
      (response) => {
        this.water = response.data.water;
        this.water.Area1Flow = this.water.Area1Flow.toFixed(1);
        this.water.Area1Ec = this.water.Area1Ec.toFixed(1);
        this.water.Area1Ph = this.water.Area1Ph.toFixed(1);
        this.water.Area2Flow = this.water.Area2Flow.toFixed(1);
        this.water.Area2Ec = this.water.Area2Ec.toFixed(1);
        this.water.Area2Ph = this.water.Area2Ph.toFixed(1);
        this.water.Area3Flow = this.water.Area3Flow.toFixed(1);
        this.water.Area3Ec = this.water.Area3Ec.toFixed(1);
        this.water.Area3Ph = this.water.Area3Ph.toFixed(1);
        this.water.Area4Flow = this.water.Area4Flow.toFixed(1);
        this.water.Area4Ec = this.water.Area4Ec.toFixed(1);
        this.water.Area4Ph = this.water.Area4Ph.toFixed(1);
        this.water.Area5Flow = this.water.Area5Flow.toFixed(1);
        this.water.Area5Ec = this.water.Area5Ec.toFixed(1);
        this.water.Area5Ph = this.water.Area5Ph.toFixed(1);
        this.water.Area6Flow = this.water.Area6Flow.toFixed(1);
        this.water.Area6Ec = this.water.Area6Ec.toFixed(1);
        this.water.Area6Ph = this.water.Area6Ph.toFixed(1);

        this.water.Area7Flow = this.water.Area7Flow.toFixed(1);
        this.water.Area7Ec = this.water.Area7Ec.toFixed(1);
        this.water.Area7Ph = this.water.Area7Ph.toFixed(1);
        this.water.Area8Flow = this.water.Area8Flow.toFixed(1);
        this.water.Area8Ec = this.water.Area8Ec.toFixed(1);
        this.water.Area8Ph = this.water.Area8Ph.toFixed(1);
        this.water.Area9Flow = this.water.Area9Flow.toFixed(1);
        this.water.Area9Ec = this.water.Area9Ec.toFixed(1);
        this.water.Area9Ph = this.water.Area9Ph.toFixed(1);
        this.water.Area10Flow = this.water.Area10Flow.toFixed(1);
        this.water.Area10Ec = this.water.Area10Ec.toFixed(1);
        this.water.Area10Ph = this.water.Area10Ph.toFixed(1);
        this.water.Area11Flow = this.water.Area11Flow.toFixed(1);
        this.water.Area11Ec = this.water.Area11Ec.toFixed(1);
        this.water.Area11Ph = this.water.Area11Ph.toFixed(1);
        this.water.Area12Flow = this.water.Area12Flow.toFixed(1);
        this.water.Area12Ec = this.water.Area12Ec.toFixed(1);
        this.water.Area12Ph = this.water.Area12Ph.toFixed(1);

        this.water.WaterLux = this.water.WaterLux.toFixed(1);
        this.water.WaterEc = this.water.WaterEc.toFixed(1);
        this.water.WaterPh = this.water.WaterPh.toFixed(1);

        this.water.EcHiLimit = this.water.EcHiLimit.toFixed(1);
        this.water.EcLowLimit = this.water.EcLowLimit.toFixed(1);
        this.water.PhHiLimit = this.water.PhHiLimit.toFixed(1);
        this.water.PhLowLimit = this.water.PhLowLimit.toFixed(1);
      },
      (error) => {
        console.log(error);
      }
    );
  },
};
</script>
<style></style>
