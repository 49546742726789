import axios from "axios";

const API_URL = "https://bsforest.net/api/";

class AuthService {
  login(user) {
    return axios
      .post(API_URL + "login", {
        id: user.id,
        password: user.password,
      })
      .then((response) => {
        if (response.data.accessToken) {
          localStorage.setItem("user", JSON.stringify(response.data));
        }

        return response.data;
      });
  }

  logout() {
    let user = localStorage.getItem("user");
    axios.post(API_URL + "logout", {
      accessToken: user.accessToken,
    });
    localStorage.removeItem("user");
  }

  register(user) {
    return axios.post(API_URL + "data/user", {
      id: user.id,
      name: user.name,
      password: user.password,
    });
  }
}

export default new AuthService();
