<template>
  <base-nav
    class="navbar-top navbar-dark"
    id="navbar-main"
    :show-toggle-button="false"
    expand
  >
    <form
      class="navbar-search navbar-search-dark form-inline mr-3 d-none d-md-flex ml-lg-auto"
    >
      <!--      <div class="form-group mb-0">-->
      <!--        <base-input-->
      <!--          placeholder="Search"-->
      <!--          class="input-group-alternative"-->
      <!--          alternative=""-->
      <!--          addon-right-icon="fas fa-search"-->
      <!--        >-->
      <!--        </base-input>-->
      <!--      </div>-->
    </form>
    <ul class="navbar-nav align-items-center d-none d-md-flex">
      <li class="nav-item dropdown">
        <base-dropdown class="nav-link pr-0">
          <template v-slot:title>
            <div class="media align-items-center">
              <!--              <span class="avatar avatar-sm rounded-circle">-->
              <!--                <img-->
              <!--                  alt="Image placeholder"-->
              <!--                  src="img/theme/team-4-800x800.jpg"-->
              <!--                />-->
              <!--              </span>-->
              <div class="media-body ml-2 d-none d-lg-block">
                <span class="mb-0 text-sm font-weight-bold">
                  {{ currentUser.name }}님 접속중</span
                >
              </div>
            </div>
          </template>
          <div class="dropdown-header noti-title">
            <h6 class="text-overflow m-0">설정</h6>
          </div>
          <router-link to="/userUpdate" class="dropdown-item">
            <i class="ni ni-single-02"></i>
            <span>비밀번호 변경</span>
          </router-link>
          <div class="dropdown-divider"></div>
          <div class="dropdown-item" @click.prevent="logout">
            <i class="ni ni-user-run"></i>
            <span>Logout</span>
          </div>
        </base-dropdown>
      </li>
    </ul>
  </base-nav>
</template>
<script>
export default {
  data() {
    return {
      activeNotifications: false,
      showMenu: false,
      searchQuery: "",
      username: "",
    };
  },
  methods: {
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    hideSidebar() {
      this.$sidebar.displaySidebar(false);
    },
    toggleMenu() {
      this.showMenu = !this.showMenu;
    },
    logout() {
      this.$store.dispatch("auth/logout");
      this.$router.push("/login");
    },
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
  mounted() {
    if (!this.currentUser) {
      this.$router.push("/login");
    }
  },
};
</script>
