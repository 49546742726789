<template>
  <div>
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
      <div class="row">
        <div class="col-xl-3 col-lg-6">
          <stats-card
            title="온도"
            type="gradient-red"
            icon="fa fa-thermometer-half"
            class="mb-4 mb-xl-0"
            v-bind:sub-title="house2.Temp"
          >
          </stats-card>
        </div>
        <div class="col-xl-3 col-lg-6">
          <stats-card
            title="습도"
            type="gradient-info"
            v-bind:sub-title="house2.Hum"
            icon="fa fa-tint"
            class="mb-4 mb-xl-0"
          >
          </stats-card>
        </div>
        <div class="col-xl-3 col-lg-6">
          <stats-card
            title="조도"
            type="gradient-orange"
            v-bind:sub-title="house2.Lux"
            icon="fa fa-sun"
            class="mb-4 mb-xl-0"
          >
          </stats-card>
        </div>
        <div class="col-xl-3 col-lg-6">
          <stats-card
            title="CO2"
            type="gradient-green"
            v-bind:sub-title="house2.Co2"
            icon="fa fa-tree"
            class="mb-4 mb-xl-0"
          >
          </stats-card>
        </div>
      </div>
    </base-header>

    <div class="container-fluid mt--7">
      <!--Charts-->
      <div class="row">
        <div class="col-xl-6 mb-5 mb-xl-0">
          <card header-classes="bg-transparent">
            <template v-slot:header>
              <div class="row align-items-center">
                <div class="col">
                  <h5 class="h3 mb-0">온도 트렌드</h5>
                </div>
              </div>
            </template>
            <div class="chart-area">
              <canvas :height="350" :id="tempChartID"></canvas>
            </div>
          </card>
        </div>

        <div class="col-xl-6">
          <card header-classes="bg-transparent">
            <template v-slot:header>
              <div class="row align-items-center">
                <div class="col">
                  <h5 class="h3 mb-0">습도 트렌드</h5>
                </div>
              </div>
            </template>
            <div class="chart-area">
              <canvas :height="350" :id="humidChartID"></canvas>
            </div>
          </card>
        </div>
      </div>
      <!-- End charts-->

      <!--Tables-->
      <div class="row mt-5">
        <div class="col-xl-12 mb-5 mb-xl-0">
          <div class="card">
            <div class="card-header border-0">
              <div class="row align-items-center">
                <div class="col">
                  <h3 class="mb-0">지온지습</h3>
                </div>
                <!--        <div class="col text-right">-->
                <!--          <a href="#!" class="btn btn-sm btn-primary">See all</a>-->
                <!--        </div>-->
              </div>
            </div>

            <div class="table-responsive">
              <table class="table text-center">
                <thead class="thead-light">
                  <tr>
                    <th colspan="2">A동</th>
                    <th colspan="2">B1동</th>
                    <th colspan="2">B2동</th>
                    <th colspan="2">C동</th>
                  </tr>
                  <tr>
                    <th>지온</th>
                    <th>지습</th>
                    <th>지온</th>
                    <th>지습</th>
                    <th>지온</th>
                    <th>지습</th>
                    <th>지온</th>
                    <th>지습</th>
                  </tr>
                </thead>
                <tbody class="list">
                  <tr>
                    <td>{{ house2.AGrTemp }}℃</td>
                    <td>{{ house2.AGrHum }}%</td>
                    <td>{{ house2.B1GrTemp }}℃</td>
                    <td>{{ house2.B1GrHum }}%</td>
                    <td>{{ house2.B2GrTemp }}℃</td>
                    <td>{{ house2.B2GrHum }}%</td>
                    <td>{{ house2.CGrTemp }}℃</td>
                    <td>{{ house2.CGrHum }}%</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <!--      <div class="row mt-5">-->
      <!--        <div class="col-xl-12 mb-5 mb-xl-0">-->
      <!--          <div class="card">-->
      <!--            <div class="card-header border-0">-->
      <!--              <div class="row align-items-center">-->
      <!--                <div class="col">-->
      <!--                  <h3 class="mb-0">1중 상태</h3>-->
      <!--                </div>-->
      <!--              </div>-->
      <!--            </div>-->
      <!--            <div class="table-responsive">-->
      <!--              <table class="table text-center">-->
      <!--                <thead class="thead-light">-->
      <!--                  <tr>-->
      <!--                    <th>1동</th>-->
      <!--                    <th>2동</th>-->
      <!--                    <th>3동</th>-->
      <!--                  </tr>-->
      <!--                </thead>-->
      <!--                <tbody class="list">-->
      <!--                  <tr>-->
      <!--                    <th>-->
      <!--                      {{ house2.House1Service ? "가동" : "정지" }}-->
      <!--                    </th>-->
      <!--                    <th>-->
      <!--                      {{ powerRoom.House2Service ? "가동" : "정지" }}-->
      <!--                    </th>-->
      <!--                    <th>-->
      <!--                      {{ powerRoom.House3Service ? "가동" : "정지" }}-->
      <!--                    </th>-->
      <!--                  </tr>-->
      <!--                </tbody>-->
      <!--              </table>-->
      <!--            </div>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--      </div>-->

      <div class="row mt-5">
        <div class="col-xl-12 mb-5 mb-xl-0">
          <div class="card">
            <div class="card-header border-0">
              <div class="row align-items-center">
                <div class="col">
                  <h3 class="mb-0">1중 운전상태</h3>
                </div>
              </div>
            </div>

            <div class="table-responsive">
              <table class="table text-center">
                <thead class="thead-light">
                  <tr>
                    <th>측창 좌상</th>
                    <th>측창 좌상</th>
                    <th>측창 우상</th>
                    <th>측창 우하</th>
                    <th>앞</th>
                    <th>뒤</th>
                  </tr>
                </thead>
                <tbody class="list">
                  <tr>
                    <th v-if="house2.L1LtOpen || house2.L1LtClose">
                      {{ house2.L1LtOpen ? "열림" : "닫힘" }}
                    </th>
                    <th v-else>정지</th>
                    <th v-if="house2.L1LdOpen || house2.L1LdClose">
                      {{ house2.L1LdOpen ? "열림" : "닫힘" }}
                    </th>
                    <th v-else>정지</th>
                    <th v-if="house2.L1RtOpen || house2.L1RtClose">
                      {{ house2.L1RtOpen ? "열림" : "닫힘" }}
                    </th>
                    <th v-else>정지</th>
                    <th v-if="house2.L1RdOpen || house2.L1RdClose">
                      {{ house2.L1RdOpen ? "열림" : "닫힘" }}
                    </th>
                    <th v-else>정지</th>
                    <th v-if="house2.Fdpen || house2.FdClose">
                      {{ house2.FdOpen ? "열림" : "닫힘" }}
                    </th>
                    <th v-else>정지</th>
                    <th v-if="house2.BdOpen || house2.BdClose">
                      {{ house2.BdOpen ? "열림" : "닫힘" }}
                    </th>
                    <th v-else>정지</th>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <!--End tables-->

      <div class="row mt-5">
        <div class="col-xl-12 mb-5 mb-xl-0">
          <div class="card">
            <div class="card-header border-0">
              <div class="row align-items-center">
                <div class="col">
                  <h3 class="mb-0">2중 운전상태</h3>
                </div>
              </div>
            </div>

            <div class="table-responsive">
              <table class="table text-center">
                <thead class="thead-light">
                  <tr>
                    <th>측벽 좌상</th>
                    <th>측벽 좌상</th>
                    <th>측벽 우상</th>
                    <th>측벽 우하</th>
                  </tr>
                </thead>
                <tbody class="list">
                  <tr>
                    <th v-if="house2.L2LtOpen || house2.L2LtClose">
                      {{ house2.L2LtOpen ? "열림" : "닫힘" }}
                    </th>
                    <th v-else>정지</th>
                    <th v-if="house2.L2LdOpen || house2.L2LdClose">
                      {{ house2.L2LdOpen ? "열림" : "닫힘" }}
                    </th>
                    <th v-else>정지</th>
                    <th v-if="house2.L2RtOpen || house2.L2RtClose">
                      {{ house2.L2RtOpen ? "열림" : "닫힘" }}
                    </th>
                    <th v-else>정지</th>
                    <th v-if="house2.L2RdOpen || house2.L2RdClose">
                      {{ house2.L2RdOpen ? "열림" : "닫힘" }}
                    </th>
                    <th v-else>정지</th>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-5">
        <div class="col-xl-12 mb-5 mb-xl-0">
          <div class="card">
            <div class="card-header border-0">
              <div class="row align-items-center">
                <div class="col">
                  <h3 class="mb-0">천장계폐기 운전상태</h3>
                </div>
              </div>
            </div>

            <div class="table-responsive">
              <table class="table text-center">
                <thead class="thead-light">
                  <tr>
                    <th>1</th>
                    <th>2</th>
                    <th>3</th>
                    <th>4</th>
                    <th>5</th>
                    <th>6</th>
                    <th>7</th>
                    <th>8</th>
                    <th>9</th>
                    <th>10</th>
                    <th>11</th>
                    <th>12</th>
                  </tr>
                </thead>
                <tbody class="list">
                  <tr>
                    <th v-if="house2.TopWin1Open || house2.TopWin1Close">
                      {{ house2.TopWin1Open ? "열림" : "닫힘" }}
                    </th>
                    <th v-else>정지</th>
                    <th v-if="house2.TopWin2Open || house2.TopWin2Close">
                      {{ house2.TopWin2Open ? "열림" : "닫힘" }}
                    </th>
                    <th v-else>정지</th>
                    <th v-if="house2.TopWin3Open || house2.TopWin3Close">
                      {{ house2.TopWin3Open ? "열림" : "닫힘" }}
                    </th>
                    <th v-else>정지</th>
                    <th v-if="house2.TopWin4Open || house2.TopWin4Close">
                      {{ house2.TopWin4Open ? "열림" : "닫힘" }}
                    </th>
                    <th v-else>정지</th>
                    <th v-if="house2.TopWin5Open || house2.TopWin5Close">
                      {{ house2.TopWin5Open ? "열림" : "닫힘" }}
                    </th>
                    <th v-else>정지</th>
                    <th v-if="house2.TopWin6Open || house2.TopWin6Close">
                      {{ house2.TopWin6Open ? "열림" : "닫힘" }}
                    </th>
                    <th v-else>정지</th>
                    <th v-if="house2.TopWin7Open || house2.TopWin7Close">
                      {{ house2.TopWin7Open ? "열림" : "닫힘" }}
                    </th>
                    <th v-else>정지</th>
                    <th v-if="house2.TopWin8Open || house2.TopWin8Close">
                      {{ house2.TopWin8Open ? "열림" : "닫힘" }}
                    </th>
                    <th v-else>정지</th>
                    <th v-if="house2.TopWin9Open || house2.TopWin9Close">
                      {{ house2.TopWin9Open ? "열림" : "닫힘" }}
                    </th>
                    <th v-else>정지</th>
                    <th v-if="house2.TopWin10Open || house2.TopWin10Close">
                      {{ house2.TopWin10Open ? "열림" : "닫힘" }}
                    </th>
                    <th v-else>정지</th>
                    <th v-if="house2.TopWin11Open || house2.TopWin11Close">
                      {{ house2.TopWin11Open ? "열림" : "닫힘" }}
                    </th>
                    <th v-else>정지</th>
                    <th v-if="house2.TopWin12Open || house2.TopWin12Close">
                      {{ house2.TopWin12Open ? "열림" : "닫힘" }}
                    </th>
                    <th v-else>정지</th>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-5">
        <div class="col-xl-12 mb-5 mb-xl-0">
          <div class="card">
            <div class="card-header border-0">
              <div class="row align-items-center">
                <div class="col">
                  <h3 class="mb-0">센서 / 휀 상태</h3>
                </div>
              </div>
            </div>

            <div class="table-responsive">
              <table class="table text-center">
                <thead class="thead-light">
                  <tr>
                    <th>우적센서</th>
                    <th>배기휀 전</th>
                    <th>배기휀 후</th>
                    <th>유동휀</th>
                  </tr>
                </thead>
                <tbody class="list">
                  <tr>
                    <th>{{ house2.Rain ? "작동중" : "정지" }}</th>
                    <th>{{ house2.ExfanFrontRun ? "작동중" : "정지" }}</th>
                    <th>{{ house2.ExfanBackRun ? "작동중" : "정지" }}</th>
                    <th>{{ house2.FanRun ? "작동중" : "정지" }}</th>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// Charts
import Chart from "chart.js";
import DataService from "../services/data.service";

let tempChart;
let humChart;

export default {
  components: {},
  data() {
    return {
      house2: {},
      chart: [],
      chartLabels: [],
      chartTemp: [],
      chartHumid: [],
      tempChartID: "tempChart",
      humidChartID: "humidChart",
    };
  },
  methods: {
    initChart() {
      // eslint-disable-next-line no-unused-vars
      tempChart = new Chart(
        document.getElementById(this.tempChartID).getContext("2d"),
        {
          type: "line",
          data: {
            labels: this.chartLabels,
            datasets: [
              {
                label: "온도",
                tension: 0.4,
                borderWidth: 4,
                borderColor: "#f96332",
                pointRadius: 0,
                backgroundColor: "transparent",
                data: this.chartTemp,
              },
            ],
          },
          options: {
            responsive: true,
            maintainAspectRatio: false,
            legend: {
              display: false,
            },
            tooltips: {
              enabled: true,
              mode: "index",
              intersect: false,
            },
            scales: {
              yAxes: [
                {
                  barPercentage: 1.6,
                  gridLines: {
                    drawBorder: false,
                    color: "rgba(29,140,248,0.0)",
                    zeroLineColor: "transparent",
                  },
                  ticks: {
                    padding: 0,
                    fontColor: "#8898aa",
                    fontSize: 13,
                    fontFamily: "Open Sans",
                  },
                },
              ],
              xAxes: [
                {
                  barPercentage: 1.6,
                  gridLines: {
                    drawBorder: false,
                    color: "rgba(29,140,248,0.0)",
                    zeroLineColor: "transparent",
                  },
                  ticks: {
                    padding: 10,
                    fontColor: "#8898aa",
                    fontSize: 13,
                    fontFamily: "Open Sans",
                  },
                },
              ],
            },
            layout: {
              padding: 0,
            },
          },
        }
      );

      // eslint-disable-next-line no-unused-vars
      humChart = new Chart(
        document.getElementById(this.humidChartID).getContext("2d"),
        {
          type: "line",
          data: {
            labels: this.chartLabels,
            datasets: [
              {
                label: "습도",
                tension: 0.4,
                borderWidth: 4,
                borderColor: "#5e72e4",
                pointRadius: 0,
                backgroundColor: "transparent",
                data: this.chartHumid,
              },
            ],
          },
          options: {
            responsive: true,
            maintainAspectRatio: false,
            legend: {
              display: false,
            },
            tooltips: {
              enabled: true,
              mode: "index",
              intersect: false,
            },
            scales: {
              yAxes: [
                {
                  barPercentage: 1.6,
                  gridLines: {
                    drawBorder: false,
                    color: "rgba(29,140,248,0.0)",
                    zeroLineColor: "transparent",
                  },
                  ticks: {
                    padding: 0,
                    fontColor: "#8898aa",
                    fontSize: 13,
                    fontFamily: "Open Sans",
                  },
                },
              ],
              xAxes: [
                {
                  barPercentage: 1.6,
                  gridLines: {
                    drawBorder: false,
                    color: "rgba(29,140,248,0.0)",
                    zeroLineColor: "transparent",
                  },
                  ticks: {
                    padding: 10,
                    fontColor: "#8898aa",
                    fontSize: 13,
                    fontFamily: "Open Sans",
                  },
                },
              ],
            },
            layout: {
              padding: 0,
            },
          },
        }
      );
    },
  },
  mounted() {
    DataService.getHouse2().then(
      (response) => {
        this.house2 = response.data.house2;
        this.house2.Temp = this.house2.Temp.toFixed(1) + " ℃";
        this.house2.Hum = this.house2.Hum.toFixed(1) + " %";
        this.house2.Lux = this.house2.Lux.toFixed(1) + " W/m";
        this.house2.Co2 = this.house2.Co2.toFixed(1) + " ppm";
        this.house2.AGrHum = this.house2.AGrHum.toFixed(1);
        this.house2.B1GrHum = this.house2.B1GrHum.toFixed(1);
        this.house2.B2GrHum = this.house2.B2GrHum.toFixed(1);
        this.house2.CGrHum = this.house2.CGrHum.toFixed(1);
        this.house2.AGrTemp = this.house2.AGrTemp.toFixed(1);
        this.house2.B1GrTemp = this.house2.B1GrTemp.toFixed(1);
        this.house2.B2GrTemp = this.house2.B2GrTemp.toFixed(1);
        this.house2.CGrTemp = this.house2.CGrTemp.toFixed(1);

        this.chart = response.data.chart;
        // 2021-12-11T18:33:00+09:00
        for (var i = this.chart.length - 1; i > -1; i--) {
          let timeString = this.chart[i].CreateDate.substr(11, 5);
          this.chartLabels.push(timeString);
          this.chartTemp.push(this.chart[i].House2Temp.toFixed(1));
          this.chartHumid.push(this.chart[i].House2Hum.toFixed(1));
        }
        this.initChart();
      },
      (error) => {
        console.log(error);
      }
    );

    // ordersChart.createChart(this.humidChartID);
  },
};
</script>
<style></style>
