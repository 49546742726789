<template>
  <div class="row justify-content-center">
    <div class="col-lg-5 col-md-7">
      <div class="card bg-secondary shadow border-0">
        <!--        <div class="card-header bg-transparent pb-5">-->
        <!--          <div class="text-muted text-center mt-2 mb-3">-->
        <!--            <small>Sign in with</small>-->
        <!--          </div>-->
        <!--          <div class="btn-wrapper text-center">-->
        <!--            <a href="#" class="btn btn-neutral btn-icon">-->
        <!--              <span class="btn-inner&#45;&#45;icon"-->
        <!--                ><img src="img/icons/common/github.svg"-->
        <!--              /></span>-->
        <!--              <span class="btn-inner&#45;&#45;text">Github</span>-->
        <!--            </a>-->
        <!--            <a href="#" class="btn btn-neutral btn-icon">-->
        <!--              <span class="btn-inner&#45;&#45;icon"-->
        <!--                ><img src="img/icons/common/google.svg"-->
        <!--              /></span>-->
        <!--              <span class="btn-inner&#45;&#45;text">Google</span>-->
        <!--            </a>-->
        <!--          </div>-->
        <!--        </div>-->
        <div class="card-body px-lg-5 py-lg-5">
          <div class="text-center text-muted mb-4">
            <small>로그인</small>
          </div>
          <form role="form">
            <base-input
              formClasses="input-group-alternative mb-3"
              placeholder="아이디"
              addon-left-icon="ni ni-email-83"
              v-model="user.id"
            >
            </base-input>

            <base-input
              formClasses="input-group-alternative mb-3"
              placeholder="비밀번호"
              type="password"
              addon-left-icon="ni ni-lock-circle-open"
              v-model="user.password"
            >
            </base-input>

            <base-checkbox class="custom-control-alternative">
              <span class="text-muted">아이디 기억</span>
            </base-checkbox>

            <div class="text-center">
              <base-button
                type="primary"
                class="my-4"
                @click.prevent="handleLogin"
                >로그인</base-button
              >
            </div>
          </form>
        </div>
      </div>
      <!--      <div class="row mt-3">-->
      <!--        <div class="col-6">-->
      <!--          <a href="#" class="text-light"><small>Forgot password?</small></a>-->
      <!--        </div>-->
      <!--        <div class="col-6 text-right">-->
      <!--          <router-link to="/register" class="text-light"-->
      <!--            ><small>Create new account</small></router-link-->
      <!--          >-->
      <!--        </div>-->
      <!--      </div>-->
    </div>
  </div>
</template>
<script>
import User from "../models/user";

export default {
  name: "login",
  data() {
    return {
      user: new User("", ""),
      loading: false,
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  created() {
    if (this.loggedIn) {
      console.log("push to house");
      this.$router.push("/house");
    }
  },
  methods: {
    handleLogin() {
      this.loading = true;

      if (this.user.id && this.user.password) {
        this.$store.dispatch("auth/login", this.user).then(
          () => {
            this.$router.push("/house");
          },
          (error) => {
            this.loading = false;
            this.message =
              (error.response && error.response.data) ||
              error.message ||
              error.toString();
          }
        );
      }
    },
  },
};
</script>
<style></style>
