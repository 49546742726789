<template>
  <div>
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
    </base-header>

    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col">
          <div class="card shadow">
            <div class="card-header border-0">
              <div class="row align-items-center">
                <div class="col">
                  <h3 class="mb-0">동작이력</h3>
                </div>
              </div>
            </div>

            <div class="table-responsive">
              <base-table
                class="table align-items-center table-flush"
                thead-classes="thead-light"
                tbody-classes="list"
                :data="history"
              >
                <template v-slot:columns>
                  <th width="30%">동작일시</th>
                  <th width="40%">동작장비</th>
                  <th>동작내역</th>
                </template>

                <template v-slot:default="row">
                  <th scope="row">
                    <div class="media align-items-center">
                      <div class="media-body">
                        <span class="name mb-0 text-sm"
                          >{{ row.item.CreateDate.substring(0, 10) }}
                          {{ row.item.CreateDate.substring(11, 16) }}</span
                        >
                      </div>
                    </div>
                  </th>
                  <td class="budget">
                    {{ row.item.Note }}
                  </td>
                  <td>
                    <span class="status"
                      >{{ row.item.PrevValue }} ->
                      {{ row.item.NextValue }}</span
                    >
                  </td>
                </template>
              </base-table>
            </div>

            <div class="card-footer d-flex justify-content-end">
              <base-pagination
                v-bind:pageCount="totalPage"
                v-bind:value="page"
                v-on:input="getHistory"
              >
              </base-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import DataService from "@/services/data.service";

export default {
  data() {
    return {
      history: [],
      page: 1,
      pageSize: 10,
      totalPage: 1,
    };
  },
  methods: {
    getHistory(page) {
      DataService.getHistory(page, this.pageSize).then(
        (response) => {
          this.history = response.data.history;
          this.page = response.data.page;
          this.totalPage = response.data.totalPage;
        },
        (response, error) => {
          response.status;
          console.log(error);
        }
      );
    },
  },
  mounted() {
    this.getHistory(this.page);
  },
};
</script>
<style></style>
