import { createRouter, createWebHashHistory } from "vue-router";

import DashboardLayout from "@/layout/DashboardLayout";
import AuthLayout from "@/layout/AuthLayout";

import Login from "../views/Login.vue";

import House from "../views/House";
import House2 from "../views/House2";
import Outdoor from "../views/Outdoor";
import PowerRoom from "../views/PowerRoom";
import Water from "../views/Water";
import Trend from "../views/Trend";
import History from "../views/History";
import UserList from "../views/UserList";
import UserRegister from "../views/UserRegister";
import UserUpdate from "../views/UserUpdate";
const routes = [
  {
    path: "/",
    redirect: "/house",
    component: DashboardLayout,
    children: [
      {
        path: "/house",
        name: "자동화연동온실",
        components: { default: House },
      },
      {
        path: "/house2",
        name: "자동화연동온실2",
        components: { default: House2 },
      },
      {
        path: "/outdoor",
        name: "야외생육시설",
        components: { default: Outdoor },
      },
      {
        path: "/powerroom",
        name: "작업동",
        components: { default: PowerRoom },
      },
      {
        path: "/water",
        name: "양액설비",
        components: { default: Water },
      },

      {
        path: "/trend",
        name: "트렌드",
        components: { default: Trend },
      },
      {
        path: "/history",
        name: "동작이력",
        components: { default: History },
      },
      {
        path: "/userList",
        name: "사용자관리",
        components: { default: UserList },
      },
      {
        path: "/userRegister",
        name: "사용자등록",
        components: { default: UserRegister },
      },
      {
        path: "/userUpdate",
        name: "사용자수정",
        components: { default: UserUpdate },
      },
    ],
  },
  {
    path: "/",
    redirect: "login",
    component: AuthLayout,
    children: [
      {
        path: "/login",
        name: "login",
        components: { default: Login },
      },
    ],
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  linkActiveClass: "active",
  meta: {
    hideFooter: true,
  },
  routes,
});

router.beforeEach((to, from, next) => {
  const publicPages = ["/login"];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem("user");

  // trying to access a restricted page + not logged in
  // redirect to login page
  if (authRequired && !loggedIn) {
    next("/login");
  } else {
    next();
  }
});

export default router;
