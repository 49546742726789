<template>
  <div>
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
      <div class="row">
        <div class="col-xl-3 col-lg-6">
          <stats-card
            title="온도"
            type="gradient-red"
            icon="fa fa-thermometer-half"
            class="mb-4 mb-xl-0"
            v-bind:sub-title="house.HouseTemp"
          >
          </stats-card>
        </div>
        <div class="col-xl-3 col-lg-6">
          <stats-card
            title="습도"
            type="gradient-info"
            v-bind:sub-title="house.HouseHum"
            icon="fa fa-tint"
            class="mb-4 mb-xl-0"
          >
          </stats-card>
        </div>
        <div class="col-xl-3 col-lg-6">
          <stats-card
            title="조도"
            type="gradient-orange"
            v-bind:sub-title="house.HouseLux"
            icon="fa fa-sun"
            class="mb-4 mb-xl-0"
          >
          </stats-card>
        </div>
        <div class="col-xl-3 col-lg-6">
          <stats-card
            title="CO2"
            type="gradient-green"
            v-bind:sub-title="house.HouseCo2"
            icon="fa fa-tree"
            class="mb-4 mb-xl-0"
          >
          </stats-card>
        </div>
      </div>
    </base-header>

    <div class="container-fluid mt--7">
      <!--Charts-->
      <div class="row">
        <div class="col-xl-6 mb-5 mb-xl-0">
          <card header-classes="bg-transparent">
            <template v-slot:header>
              <div class="row align-items-center">
                <div class="col">
                  <h5 class="h3 mb-0">온도 트렌드</h5>
                </div>
              </div>
            </template>
            <div class="chart-area">
              <canvas :height="350" :id="tempChartID"></canvas>
            </div>
          </card>
        </div>

        <div class="col-xl-6">
          <card header-classes="bg-transparent">
            <template v-slot:header>
              <div class="row align-items-center">
                <div class="col">
                  <h5 class="h3 mb-0">습도 트렌드</h5>
                </div>
              </div>
            </template>
            <div class="chart-area">
              <canvas :height="350" :id="humidChartID"></canvas>
            </div>
          </card>
        </div>
      </div>
      <!-- End charts-->

      <!--Tables-->
      <div class="row mt-5">
        <div class="col-xl-12 mb-5 mb-xl-0">
          <div class="card">
            <div class="card-header border-0">
              <div class="row align-items-center">
                <div class="col">
                  <h3 class="mb-0">지온지습</h3>
                </div>
                <!--        <div class="col text-right">-->
                <!--          <a href="#!" class="btn btn-sm btn-primary">See all</a>-->
                <!--        </div>-->
              </div>
            </div>

            <div class="table-responsive">
              <table class="table text-center">
                <thead class="thead-light">
                  <tr>
                    <th rowspan="2"></th>
                    <th colspan="2">1동</th>
                    <th colspan="2">2동</th>
                    <th colspan="2">3동</th>
                  </tr>
                  <tr>
                    <th>지온</th>
                    <th>지습</th>
                    <th>지온</th>
                    <th>지습</th>
                    <th>지온</th>
                    <th>지습</th>
                  </tr>
                </thead>
                <tbody class="list">
                  <tr>
                    <th>1구역</th>
                    <td>{{ house.GrTemp_11 }}℃</td>
                    <td>{{ house.GrHum_11 }}%</td>
                    <td>{{ house.GrTemp_21 }}℃</td>
                    <td>{{ house.GrHum_21 }}%</td>
                    <td>{{ house.GrTemp_31 }}℃</td>
                    <td>{{ house.GrHum_31 }}%</td>
                  </tr>
                  <tr>
                    <th>2구역</th>
                    <td>{{ house.GrTemp_12 }}℃</td>
                    <td>{{ house.GrHum_12 }}%</td>
                    <td>{{ house.GrTemp_22 }}℃</td>
                    <td>{{ house.GrHum_22 }}%</td>
                    <td>{{ house.GrTemp_32 }}℃</td>
                    <td>{{ house.GrHum_32 }}%</td>
                  </tr>
                  <tr>
                    <th>3구역</th>
                    <td>{{ house.GrTemp_13 }}℃</td>
                    <td>{{ house.GrHum_13 }}%</td>
                    <td>{{ house.GrTemp_23 }}℃</td>
                    <td>{{ house.GrHum_23 }}%</td>
                    <td>{{ house.GrTemp_33 }}℃</td>
                    <td>{{ house.GrHum_33 }}%</td>
                  </tr>
                  <tr>
                    <td
                      colspan="7"
                      style="text-align: center; font-weight: bold"
                    >
                      평균지온 : {{ house.AvgTemp }}℃ / 평균지습 :
                      {{ house.AvgHum }}%
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-5">
        <div class="col-xl-12 mb-5 mb-xl-0">
          <div class="card">
            <div class="card-header border-0">
              <div class="row align-items-center">
                <div class="col">
                  <h3 class="mb-0">자주식살수기 운전상태</h3>
                </div>
              </div>
            </div>
            <div class="table-responsive">
              <table class="table text-center">
                <thead class="thead-light">
                  <tr>
                    <th>1동</th>
                    <th>2동</th>
                    <th>3동</th>
                  </tr>
                </thead>
                <tbody class="list">
                  <tr>
                    <th>
                      {{ powerRoom.House1Service ? "가동" : "정지" }}
                    </th>
                    <th>
                      {{ powerRoom.House2Service ? "가동" : "정지" }}
                    </th>
                    <th>
                      {{ powerRoom.House3Service ? "가동" : "정지" }}
                    </th>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-5">
        <div class="col-xl-12 mb-5 mb-xl-0">
          <div class="card">
            <div class="card-header border-0">
              <div class="row align-items-center">
                <div class="col">
                  <h3 class="mb-0">자동화연동온실 운전상태</h3>
                </div>
              </div>
            </div>

            <div class="table-responsive">
              <table class="table text-center">
                <thead class="thead-light">
                  <tr>
                    <th>측창</th>
                    <th>천창</th>
                    <th>수평커튼</th>
                    <th>측면커튼</th>
                    <th>환풍기</th>
                  </tr>
                </thead>
                <tbody class="list">
                  <tr>
                    <th v-if="house.SideWinOpen || house.SideWindClose">
                      {{ house.SideWinOpen ? "열림" : "닫힘" }}
                    </th>
                    <th v-else>정지</th>
                    <th v-if="house.TopWinOpen || house.TopeWindClose">
                      {{ house.TopWinOpen ? "열림" : "닫힘" }}
                    </th>
                    <th v-else>정지</th>
                    <th v-if="house.SideCurOpen || house.SideCurdClose">
                      {{ house.SideCurOpen ? "열림" : "닫힘" }}
                    </th>
                    <th v-else>정지</th>
                    <th v-if="house.TopCurOpen || house.TopCurClose">
                      {{ house.TopCurOpen ? "열림" : "닫힘" }}
                    </th>
                    <th v-else>정지</th>
                    <th>{{ house.FanRun ? "작동중" : "정지" }}</th>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <!--End tables-->

      <div class="row mt-5">
        <div class="col-xl-12 mb-5 mb-xl-0">
          <div class="card">
            <div class="card-header border-0">
              <div class="row align-items-center">
                <div class="col">
                  <h3 class="mb-0">환기창 운전상태</h3>
                </div>
              </div>
            </div>

            <div class="table-responsive">
              <table class="table text-center">
                <thead class="thead-light">
                  <tr>
                    <th colspan="10">환기창</th>
                    <th rowspan="2">내부환풍기</th>
                  </tr>
                  <tr>
                    <th>1</th>
                    <th>2</th>
                    <th>3</th>
                    <th>4</th>
                    <th>5</th>
                    <th>6</th>
                    <th>7</th>
                    <th>8</th>
                    <th>9</th>
                    <th>10</th>
                  </tr>
                </thead>
                <tbody class="list">
                  <tr>
                    <th v-if="house.VentWin1Open || house.VentWin1Close">
                      {{ house.VentWin1Open ? "열림" : "닫힘" }}
                    </th>
                    <th v-else>정지</th>
                    <th v-if="house.VentWin2Open || house.VentWin2Close">
                      {{ house.VentWin2Open ? "열림" : "닫힘" }}
                    </th>
                    <th v-else>정지</th>
                    <th v-if="house.VentWin3Open || house.VentWin3Close">
                      {{ house.VentWin3Open ? "열림" : "닫힘" }}
                    </th>
                    <th v-else>정지</th>
                    <th v-if="house.VentWin4Open || house.VentWin4Close">
                      {{ house.VentWin4Open ? "열림" : "닫힘" }}
                    </th>
                    <th v-else>정지</th>
                    <th v-if="house.VentWin5Open || house.VentWin5Close">
                      {{ house.VentWin5Open ? "열림" : "닫힘" }}
                    </th>
                    <th v-else>정지</th>
                    <th v-if="house.VentWin6Open || house.VentWin6Close">
                      {{ house.VentWin6Open ? "열림" : "닫힘" }}
                    </th>
                    <th v-else>정지</th>
                    <th v-if="house.VentWin7Open || house.VentWin7Close">
                      {{ house.VentWin7Open ? "열림" : "닫힘" }}
                    </th>
                    <th v-else>정지</th>
                    <th v-if="house.VentWin8Open || house.VentWin8Close">
                      {{ house.VentWin8Open ? "열림" : "닫힘" }}
                    </th>
                    <th v-else>정지</th>
                    <th v-if="house.VentWin9Open || house.VentWin9Close">
                      {{ house.VentWin9Open ? "열림" : "닫힘" }}
                    </th>
                    <th v-else>정지</th>
                    <th v-if="house.VentWin10Open || house.VentWin10Close">
                      {{ house.VentWin10Open ? "열림" : "닫힘" }}
                    </th>
                    <th v-else>정지</th>
                    <th>{{ house.InFanRun ? "작동중" : "정지" }}</th>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <!--End tables-->
    </div>
  </div>
</template>
<script>
// Charts
import Chart from "chart.js";
import DataService from "../services/data.service";

let tempChart;
let humChart;

export default {
  components: {},
  data() {
    return {
      house: {},
      powerRoom: {},
      chart: [],
      chartLabels: [],
      chartTemp: [],
      chartHumid: [],
      tempChartID: "tempChart",
      humidChartID: "humidChart",
    };
  },
  methods: {
    initChart() {
      // eslint-disable-next-line no-unused-vars
      tempChart = new Chart(
        document.getElementById(this.tempChartID).getContext("2d"),
        {
          type: "line",
          data: {
            labels: this.chartLabels,
            datasets: [
              {
                label: "온도",
                tension: 0.4,
                borderWidth: 4,
                borderColor: "#f96332",
                pointRadius: 0,
                backgroundColor: "transparent",
                data: this.chartTemp,
              },
            ],
          },
          options: {
            responsive: true,
            maintainAspectRatio: false,
            legend: {
              display: false,
            },
            tooltips: {
              enabled: true,
              mode: "index",
              intersect: false,
            },
            scales: {
              yAxes: [
                {
                  barPercentage: 1.6,
                  gridLines: {
                    drawBorder: false,
                    color: "rgba(29,140,248,0.0)",
                    zeroLineColor: "transparent",
                  },
                  ticks: {
                    padding: 0,
                    fontColor: "#8898aa",
                    fontSize: 13,
                    fontFamily: "Open Sans",
                  },
                },
              ],
              xAxes: [
                {
                  barPercentage: 1.6,
                  gridLines: {
                    drawBorder: false,
                    color: "rgba(29,140,248,0.0)",
                    zeroLineColor: "transparent",
                  },
                  ticks: {
                    padding: 10,
                    fontColor: "#8898aa",
                    fontSize: 13,
                    fontFamily: "Open Sans",
                  },
                },
              ],
            },
            layout: {
              padding: 0,
            },
          },
        }
      );

      // eslint-disable-next-line no-unused-vars
      humChart = new Chart(
        document.getElementById(this.humidChartID).getContext("2d"),
        {
          type: "line",
          data: {
            labels: this.chartLabels,
            datasets: [
              {
                label: "습도",
                tension: 0.4,
                borderWidth: 4,
                borderColor: "#5e72e4",
                pointRadius: 0,
                backgroundColor: "transparent",
                data: this.chartHumid,
              },
            ],
          },
          options: {
            responsive: true,
            maintainAspectRatio: false,
            legend: {
              display: false,
            },
            tooltips: {
              enabled: true,
              mode: "index",
              intersect: false,
            },
            scales: {
              yAxes: [
                {
                  barPercentage: 1.6,
                  gridLines: {
                    drawBorder: false,
                    color: "rgba(29,140,248,0.0)",
                    zeroLineColor: "transparent",
                  },
                  ticks: {
                    padding: 0,
                    fontColor: "#8898aa",
                    fontSize: 13,
                    fontFamily: "Open Sans",
                  },
                },
              ],
              xAxes: [
                {
                  barPercentage: 1.6,
                  gridLines: {
                    drawBorder: false,
                    color: "rgba(29,140,248,0.0)",
                    zeroLineColor: "transparent",
                  },
                  ticks: {
                    padding: 10,
                    fontColor: "#8898aa",
                    fontSize: 13,
                    fontFamily: "Open Sans",
                  },
                },
              ],
            },
            layout: {
              padding: 0,
            },
          },
        }
      );
    },
  },
  mounted() {
    DataService.getHouse().then(
      (response) => {
        this.house = response.data.house;
        this.house.HouseTemp = this.house.HouseTemp.toFixed(1) + " ℃";
        this.house.HouseHum = this.house.HouseHum.toFixed(1) + " %";
        this.house.HouseLux = this.house.HouseLux.toFixed(1) + " W/m";
        this.house.HouseCo2 = this.house.HouseCo2.toFixed(1) + " ppm";
        this.house.AvgHum = this.house.AvgHum.toFixed(1);
        this.house.AvgTemp = this.house.AvgTemp.toFixed(1);
        this.house.GrHum_11 = this.house.GrHum_11.toFixed(1);
        this.house.GrHum_12 = this.house.GrHum_12.toFixed(1);
        this.house.GrHum_13 = this.house.GrHum_13.toFixed(1);
        this.house.GrHum_21 = this.house.GrHum_21.toFixed(1);
        this.house.GrHum_22 = this.house.GrHum_22.toFixed(1);
        this.house.GrHum_23 = this.house.GrHum_23.toFixed(1);
        this.house.GrHum_31 = this.house.GrHum_31.toFixed(1);
        this.house.GrHum_32 = this.house.GrHum_32.toFixed(1);
        this.house.GrHum_33 = this.house.GrHum_33.toFixed(1);
        this.house.GrTemp_11 = this.house.GrTemp_11.toFixed(1);
        this.house.GrTemp_12 = this.house.GrTemp_12.toFixed(1);
        this.house.GrTemp_13 = this.house.GrTemp_13.toFixed(1);
        this.house.GrTemp_21 = this.house.GrTemp_21.toFixed(1);
        this.house.GrTemp_22 = this.house.GrTemp_22.toFixed(1);
        this.house.GrTemp_23 = this.house.GrTemp_23.toFixed(1);
        this.house.GrTemp_31 = this.house.GrTemp_31.toFixed(1);
        this.house.GrTemp_32 = this.house.GrTemp_32.toFixed(1);
        this.house.GrTemp_33 = this.house.GrTemp_33.toFixed(1);

        this.powerRoom = response.data.powerRoom;
        this.chart = response.data.chart;
        // 2021-12-11T18:33:00+09:00
        for (var i = this.chart.length - 1; i > -1; i--) {
          let timeString = this.chart[i].CreateDate.substr(11, 5);
          this.chartLabels.push(timeString);
          this.chartTemp.push(this.chart[i].HouseTemp.toFixed(1));
          this.chartHumid.push(this.chart[i].HouseHum.toFixed(1));
        }
        this.initChart();
      },
      (error) => {
        console.log(error);
      }
    );

    // ordersChart.createChart(this.humidChartID);
  },
};
</script>
<style></style>
