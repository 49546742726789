<template>
  <div>
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
    </base-header>

    <div class="container-fluid mt--7">
      <!--Charts-->
      <div class="row">
        <div class="col-xl-6 mb-5 mb-xl-0">
          <card header-classes="bg-transparent">
            <template v-slot:header>
              <div class="row align-items-center">
                <div class="col">
                  <h5 class="h3 mb-0">야외 지온 지습</h5>
                </div>
              </div>
            </template>
            <div class="chart-area">
              <canvas :height="350" :id="outdoorChartId"></canvas>
            </div>
          </card>
        </div>

        <div class="col-xl-6">
          <card header-classes="bg-transparent">
            <template v-slot:header>
              <div class="row align-items-center">
                <div class="col">
                  <h5 class="h3 mb-0">온실 Co2 조도</h5>
                </div>
              </div>
            </template>
            <div class="chart-area">
              <canvas :height="350" :id="houseCo2LuxChartId"></canvas>
            </div>
          </card>
        </div>
      </div>

      <div class="row mt-5">
        <div class="col-xl-6 mb-5 mb-xl-0">
          <card header-classes="bg-transparent">
            <template v-slot:header>
              <div class="row align-items-center">
                <div class="col">
                  <h5 class="h3 mb-0">온실 1동 지온</h5>
                </div>
              </div>
            </template>
            <div class="chart-area">
              <canvas :height="350" :id="house1TempChartId"></canvas>
            </div>
          </card>
        </div>

        <div class="col-xl-6">
          <card header-classes="bg-transparent">
            <template v-slot:header>
              <div class="row align-items-center">
                <div class="col">
                  <h5 class="h3 mb-0">온실 1동 지습</h5>
                </div>
              </div>
            </template>
            <div class="chart-area">
              <canvas :height="350" :id="house1HumChartId"></canvas>
            </div>
          </card>
        </div>
      </div>

      <div class="row mt-5">
        <div class="col-xl-6 mb-5 mb-xl-0">
          <card header-classes="bg-transparent">
            <template v-slot:header>
              <div class="row align-items-center">
                <div class="col">
                  <h5 class="h3 mb-0">온실 2동 지온</h5>
                </div>
              </div>
            </template>
            <div class="chart-area">
              <canvas :height="350" :id="house2TempChartId"></canvas>
            </div>
          </card>
        </div>

        <div class="col-xl-6">
          <card header-classes="bg-transparent">
            <template v-slot:header>
              <div class="row align-items-center">
                <div class="col">
                  <h5 class="h3 mb-0">온실 2동 지습</h5>
                </div>
              </div>
            </template>
            <div class="chart-area">
              <canvas :height="350" :id="house2HumChartId"></canvas>
            </div>
          </card>
        </div>
      </div>

      <div class="row mt-5">
        <div class="col-xl-6 mb-5 mb-xl-0">
          <card header-classes="bg-transparent">
            <template v-slot:header>
              <div class="row align-items-center">
                <div class="col">
                  <h5 class="h3 mb-0">온실 3동 지온</h5>
                </div>
              </div>
            </template>
            <div class="chart-area">
              <canvas :height="350" :id="house3TempChartId"></canvas>
            </div>
          </card>
        </div>

        <div class="col-xl-6">
          <card header-classes="bg-transparent">
            <template v-slot:header>
              <div class="row align-items-center">
                <div class="col">
                  <h5 class="h3 mb-0">온실 3동 지습</h5>
                </div>
              </div>
            </template>
            <div class="chart-area">
              <canvas :height="350" :id="house3HumChartId"></canvas>
            </div>
          </card>
        </div>
      </div>
      <!-- End charts-->

      <div class="row mt-5">
        <div class="col-xl-6 mb-5 mb-xl-0">
          <card header-classes="bg-transparent">
            <template v-slot:header>
              <div class="row align-items-center">
                <div class="col">
                  <h5 class="h3 mb-0">온실2 지온</h5>
                </div>
              </div>
            </template>
            <div class="chart-area">
              <canvas :height="350" :id="houseTempChart2Id"></canvas>
            </div>
          </card>
        </div>

        <div class="col-xl-6">
          <card header-classes="bg-transparent">
            <template v-slot:header>
              <div class="row align-items-center">
                <div class="col">
                  <h5 class="h3 mb-0">온실2 지습</h5>
                </div>
              </div>
            </template>
            <div class="chart-area">
              <canvas :height="350" :id="houseHumChart2Id"></canvas>
            </div>
          </card>
        </div>
      </div>
      <!-- End charts-->

      <div class="row mt-5">
        <div class="col-xl-6 mb-5 mb-xl-0">
          <card header-classes="bg-transparent">
            <template v-slot:header>
              <div class="row align-items-center">
                <div class="col">
                  <h5 class="h3 mb-0">온실2 Co2 조도</h5>
                </div>
              </div>
            </template>
            <div class="chart-area">
              <canvas :height="350" :id="houseCo2LuxChart2Id"></canvas>
            </div>
          </card>
        </div>
      </div>
      <!-- End charts-->
    </div>
  </div>
</template>
<script>
import Chart from "chart.js";
import DataService from "../services/data.service";

let outdoorChart;
let houseCo2LuxChart;
let house1TempChart;
let house2TempChart;
let house3TempChart;
let house1HumChart;
let house2HumChart;
let house3HumChart;

let houseCo2LuxChart2;
let houseTempChart2;
let houseHumChart2;

export default {
  components: {},
  data() {
    return {
      chart: [],
      chartLabels: [],
      chartOut: {
        temp1: [],
        hum1: [],
        temp2: [],
        hum2: [],
        temp3: [],
        hum3: [],
      },
      chartHoueCo2Lux: {
        co2: [],
        lux: [],
      },
      chartHouse1Temp: {
        area1: [],
        area2: [],
        area3: [],
      },
      chartHouse2Temp: {
        area1: [],
        area2: [],
        area3: [],
      },
      chartHouse3Temp: {
        area1: [],
        area2: [],
        area3: [],
      },
      chartHouse1Hum: {
        area1: [],
        area2: [],
        area3: [],
      },
      chartHouse2Hum: {
        area1: [],
        area2: [],
        area3: [],
      },
      chartHouse3Hum: {
        area1: [],
        area2: [],
        area3: [],
      },
      chart2HoueCo2Lux: {
        co2: [],
        lux: [],
      },
      chart2HouseHum: {
        areaA: [],
        areaB1: [],
        areaB2: [],
        areaC: [],
      },
      chart2HouseTemp: {
        areaA: [],
        areaB1: [],
        areaB2: [],
        areaC: [],
      },
      chartTempid: [],
      outdoorChartId: "outdoorChart",
      houseCo2LuxChartId: "houseCo2LuxChart",
      house1TempChartId: "house1TempChart",
      house2TempChartId: "house2TempChart",
      house3TempChartId: "house3TempChart",
      house1HumChartId: "house1HumChart",
      house2HumChartId: "house2HumChart",
      house3HumChartId: "house3HumChart",

      houseCo2LuxChart2Id: "houseCo2LuxChart2",
      houseHumChart2Id: "houseHumChart2",
      houseTempChart2Id: "houseTempChart2",
    };
  },
  mounted() {
    DataService.getTrend().then(
      (response) => {
        this.chart = response.data.chart;

        for (var i = this.chart.length - 1; i > -1; i--) {
          let timeString = this.chart[i].CreateDate.substr(11, 5);
          this.chartLabels.push(timeString);

          this.chartOut.temp1.push(this.chart[i].OutGrTemp_11.toFixed(1));
          this.chartOut.hum1.push(this.chart[i].OutGrHum_11.toFixed(1));
          this.chartOut.temp2.push(this.chart[i].Out1Temp.toFixed(1));
          this.chartOut.hum2.push(this.chart[i].Out1Hum.toFixed(1));
          this.chartOut.temp3.push(this.chart[i].Out2Temp.toFixed(1));
          this.chartOut.hum3.push(this.chart[i].Out2Hum.toFixed(1));

          this.chartHoueCo2Lux.co2.push(this.chart[i].HouseCo2.toFixed(1));
          this.chartHoueCo2Lux.lux.push(this.chart[i].HouseLux.toFixed(1));

          this.chartHouse1Temp.area1.push(this.chart[i].GrTemp_11.toFixed(1));
          this.chartHouse1Temp.area2.push(this.chart[i].GrTemp_12.toFixed(1));
          this.chartHouse1Temp.area3.push(this.chart[i].GrTemp_13.toFixed(1));

          this.chartHouse2Temp.area1.push(this.chart[i].GrTemp_21.toFixed(1));
          this.chartHouse2Temp.area2.push(this.chart[i].GrTemp_22.toFixed(1));
          this.chartHouse2Temp.area3.push(this.chart[i].GrTemp_23.toFixed(1));

          this.chartHouse3Temp.area1.push(this.chart[i].GrTemp_31.toFixed(1));
          this.chartHouse3Temp.area2.push(this.chart[i].GrTemp_32.toFixed(1));
          this.chartHouse3Temp.area3.push(this.chart[i].GrTemp_33.toFixed(1));

          this.chartHouse1Hum.area1.push(this.chart[i].GrHum_11.toFixed(1));
          this.chartHouse1Hum.area2.push(this.chart[i].GrHum_12.toFixed(1));
          this.chartHouse1Hum.area3.push(this.chart[i].GrHum_13.toFixed(1));

          this.chartHouse2Hum.area1.push(this.chart[i].GrHum_21.toFixed(1));
          this.chartHouse2Hum.area2.push(this.chart[i].GrHum_22.toFixed(1));
          this.chartHouse2Hum.area3.push(this.chart[i].GrHum_23.toFixed(1));

          this.chartHouse3Hum.area1.push(this.chart[i].GrHum_31.toFixed(1));
          this.chartHouse3Hum.area2.push(this.chart[i].GrHum_32.toFixed(1));
          this.chartHouse3Hum.area3.push(this.chart[i].GrHum_33.toFixed(1));

          this.chart2HoueCo2Lux.co2.push(this.chart[i].House2Co2.toFixed(1));
          this.chart2HoueCo2Lux.lux.push(this.chart[i].House2Lux.toFixed(1));

          this.chart2HouseHum.areaA.push(this.chart[i].AGrHum.toFixed(1));
          this.chart2HouseHum.areaB1.push(this.chart[i].B1GrHum.toFixed(1));
          this.chart2HouseHum.areaB2.push(this.chart[i].B2GrHum.toFixed(1));
          this.chart2HouseHum.areaC.push(this.chart[i].CGrHum.toFixed(1));

          this.chart2HouseTemp.areaA.push(this.chart[i].AGrTemp.toFixed(1));
          this.chart2HouseTemp.areaB1.push(this.chart[i].B1GrTemp.toFixed(1));
          this.chart2HouseTemp.areaB2.push(this.chart[i].B2GrTemp.toFixed(1));
          this.chart2HouseTemp.areaC.push(this.chart[i].CGrTemp.toFixed(1));
        }
        this.initChart();
      },
      (error) => {
        console.log(error);
      }
    );
  },
  methods: {
    initChart() {
      // eslint-disable-next-line no-unused-vars
      outdoorChart = new Chart(
        document.getElementById(this.outdoorChartId).getContext("2d"),
        {
          type: "line",
          data: {
            labels: this.chartLabels,
            datasets: [
              {
                label: "1동 지온",
                tension: 0.4,
                borderWidth: 4,
                borderColor: "#f96332",
                pointRadius: 0,
                backgroundColor: "transparent",
                data: this.chartOut.temp1,
                yAxisId: "y-axis-0",
              },
              {
                label: "2동 지온",
                tension: 0.4,
                borderWidth: 4,
                borderColor: "#f97e32",
                pointRadius: 0,
                backgroundColor: "transparent",
                data: this.chartOut.temp2,
              },
              {
                label: "3동 지온",
                tension: 0.4,
                borderWidth: 4,
                borderColor: "#f9ba32",
                pointRadius: 0,
                backgroundColor: "transparent",
                data: this.chartOut.temp3,
              },
              {
                label: "1동 지습",
                tension: 0.4,
                borderWidth: 4,
                borderColor: "#5e72e4",
                pointRadius: 0,
                backgroundColor: "transparent",
                data: this.chartOut.hum1,
                yAxisId: "y-axis-1",
              },
              {
                label: "2동 지습",
                tension: 0.4,
                borderWidth: 4,
                borderColor: "#5eb5e4",
                pointRadius: 0,
                backgroundColor: "transparent",
                data: this.chartOut.hum2,
              },
              {
                label: "3동 지습",
                tension: 0.4,
                borderWidth: 4,
                borderColor: "#136054",
                pointRadius: 0,
                backgroundColor: "transparent",
                data: this.chartOut.hum3,
              },
            ],
          },
          options: {
            responsive: true,
            maintainAspectRatio: false,
            legend: {
              display: true,
            },
            tooltips: {
              enabled: true,
              mode: "index",
              intersect: false,
            },
            scales: {
              yAxes: [
                {
                  id: "y-axis-0",
                  position: "left",
                  barPercentage: 1.6,
                  gridLines: {
                    drawBorder: false,
                    color: "rgba(29,140,248,0.0)",
                    zeroLineColor: "transparent",
                  },
                  ticks: {
                    padding: 0,
                    fontColor: "#8898aa",
                    fontSize: 13,
                    fontFamily: "Open Sans",
                  },
                },
                {
                  id: "y-axis-1",
                  position: "right",
                  barPercentage: 1.6,
                  gridLines: {
                    drawBorder: false,
                    color: "rgba(29,140,248,0.0)",
                    zeroLineColor: "transparent",
                  },
                  ticks: {
                    padding: 0,
                    fontColor: "#8898aa",
                    fontSize: 13,
                    fontFamily: "Open Sans",
                  },
                },
              ],
              xAxes: [
                {
                  barPercentage: 1.6,
                  gridLines: {
                    drawBorder: false,
                    color: "rgba(29,140,248,0.0)",
                    zeroLineColor: "transparent",
                  },
                  ticks: {
                    padding: 10,
                    fontColor: "#8898aa",
                    fontSize: 13,
                    fontFamily: "Open Sans",
                  },
                },
              ],
            },
            layout: {
              padding: 0,
            },
          },
        }
      );

      // eslint-disable-next-line no-unused-vars
      houseCo2LuxChart = new Chart(
        document.getElementById(this.houseCo2LuxChartId).getContext("2d"),
        {
          type: "line",
          data: {
            labels: this.chartLabels,
            datasets: [
              {
                label: "Co2",
                tension: 0.4,
                borderWidth: 4,
                borderColor: "#f96332",
                pointRadius: 0,
                backgroundColor: "transparent",
                yAxisId: "y-axis-0",
                data: this.chartHoueCo2Lux.co2,
              },
              {
                label: "조도",
                tension: 0.4,
                borderWidth: 4,
                borderColor: "#5e72e4",
                pointRadius: 0,
                backgroundColor: "transparent",
                yAxisId: "y-axis-1",
                data: this.chartHoueCo2Lux.lux,
              },
            ],
          },
          options: {
            responsive: true,
            maintainAspectRatio: false,
            legend: {
              display: true,
            },
            tooltips: {
              enabled: true,
              mode: "index",
              intersect: false,
            },
            scales: {
              yAxes: [
                {
                  id: "y-axis-0",
                  position: "left",
                  barPercentage: 1.6,
                  gridLines: {
                    drawBorder: false,
                    color: "rgba(29,140,248,0.0)",
                    zeroLineColor: "transparent",
                  },
                  ticks: {
                    padding: 0,
                    fontColor: "#8898aa",
                    fontSize: 13,
                    fontFamily: "Open Sans",
                  },
                },
                {
                  id: "y-axis-1",
                  position: "right",
                  barPercentage: 1.6,
                  gridLines: {
                    drawBorder: false,
                    color: "rgba(29,140,248,0.0)",
                    zeroLineColor: "transparent",
                  },
                  ticks: {
                    padding: 0,
                    fontColor: "#8898aa",
                    fontSize: 13,
                    fontFamily: "Open Sans",
                  },
                },
              ],
              xAxes: [
                {
                  barPercentage: 1.6,
                  gridLines: {
                    drawBorder: false,
                    color: "rgba(29,140,248,0.0)",
                    zeroLineColor: "transparent",
                  },
                  ticks: {
                    padding: 10,
                    fontColor: "#8898aa",
                    fontSize: 13,
                    fontFamily: "Open Sans",
                  },
                },
              ],
            },
            layout: {
              padding: 0,
            },
          },
        }
      );

      // eslint-disable-next-line no-unused-vars
      house1TempChart = new Chart(
        document.getElementById(this.house1TempChartId).getContext("2d"),
        {
          type: "line",
          data: {
            labels: this.chartLabels,
            datasets: [
              {
                label: "1구역",
                tension: 0.4,
                borderWidth: 4,
                borderColor: "#f96332",
                pointRadius: 0,
                backgroundColor: "transparent",
                data: this.chartHouse1Temp.area1,
              },
              {
                label: "2구역",
                tension: 0.4,
                borderWidth: 4,
                borderColor: "#5e72e4",
                pointRadius: 0,
                backgroundColor: "transparent",
                data: this.chartHouse1Temp.area2,
              },
              {
                label: "3구역",
                tension: 0.4,
                borderWidth: 4,
                borderColor: "rgba(180,180,18,0.75)",
                pointRadius: 0,
                backgroundColor: "transparent",
                data: this.chartHouse1Temp.area3,
              },
            ],
          },
          options: {
            responsive: true,
            maintainAspectRatio: false,
            legend: {
              display: true,
            },
            tooltips: {
              enabled: true,
              mode: "index",
              intersect: false,
            },
            scales: {
              yAxes: [
                {
                  barPercentage: 1.6,
                  gridLines: {
                    drawBorder: false,
                    color: "rgba(29,140,248,0.0)",
                    zeroLineColor: "transparent",
                  },
                  ticks: {
                    padding: 0,
                    fontColor: "#8898aa",
                    fontSize: 13,
                    fontFamily: "Open Sans",
                  },
                },
              ],
              xAxes: [
                {
                  barPercentage: 1.6,
                  gridLines: {
                    drawBorder: false,
                    color: "rgba(29,140,248,0.0)",
                    zeroLineColor: "transparent",
                  },
                  ticks: {
                    padding: 10,
                    fontColor: "#8898aa",
                    fontSize: 13,
                    fontFamily: "Open Sans",
                  },
                },
              ],
            },
            layout: {
              padding: 0,
            },
          },
        }
      );

      // eslint-disable-next-line no-unused-vars
      house1HumChart = new Chart(
        document.getElementById(this.house1HumChartId).getContext("2d"),
        {
          type: "line",
          data: {
            labels: this.chartLabels,
            datasets: [
              {
                label: "1구역",
                tension: 0.4,
                borderWidth: 4,
                borderColor: "#f96332",
                pointRadius: 0,
                backgroundColor: "transparent",
                data: this.chartHouse1Hum.area1,
              },
              {
                label: "2구역",
                tension: 0.4,
                borderWidth: 4,
                borderColor: "#5e72e4",
                pointRadius: 0,
                backgroundColor: "transparent",
                data: this.chartHouse1Hum.area2,
              },
              {
                label: "3구역",
                tension: 0.4,
                borderWidth: 4,
                borderColor: "rgba(180,180,18,0.75)",
                pointRadius: 0,
                backgroundColor: "transparent",
                data: this.chartHouse1Hum.area3,
              },
            ],
          },
          options: {
            responsive: true,
            maintainAspectRatio: false,
            legend: {
              display: true,
            },
            tooltips: {
              enabled: true,
              mode: "index",
              intersect: false,
            },
            scales: {
              yAxes: [
                {
                  barPercentage: 1.6,
                  gridLines: {
                    drawBorder: false,
                    color: "rgba(29,140,248,0.0)",
                    zeroLineColor: "transparent",
                  },
                  ticks: {
                    padding: 0,
                    fontColor: "#8898aa",
                    fontSize: 13,
                    fontFamily: "Open Sans",
                  },
                },
              ],
              xAxes: [
                {
                  barPercentage: 1.6,
                  gridLines: {
                    drawBorder: false,
                    color: "rgba(29,140,248,0.0)",
                    zeroLineColor: "transparent",
                  },
                  ticks: {
                    padding: 10,
                    fontColor: "#8898aa",
                    fontSize: 13,
                    fontFamily: "Open Sans",
                  },
                },
              ],
            },
            layout: {
              padding: 0,
            },
          },
        }
      );

      // eslint-disable-next-line no-unused-vars
      house2TempChart = new Chart(
        document.getElementById(this.house2TempChartId).getContext("2d"),
        {
          type: "line",
          data: {
            labels: this.chartLabels,
            datasets: [
              {
                label: "1구역",
                tension: 0.4,
                borderWidth: 4,
                borderColor: "#f96332",
                pointRadius: 0,
                backgroundColor: "transparent",
                data: this.chartHouse2Temp.area1,
              },
              {
                label: "2구역",
                tension: 0.4,
                borderWidth: 4,
                borderColor: "#5e72e4",
                pointRadius: 0,
                backgroundColor: "transparent",
                data: this.chartHouse2Temp.area2,
              },
              {
                label: "3구역",
                tension: 0.4,
                borderWidth: 4,
                borderColor: "rgba(180,180,18,0.75)",
                pointRadius: 0,
                backgroundColor: "transparent",
                data: this.chartHouse2Temp.area3,
              },
            ],
          },
          options: {
            responsive: true,
            maintainAspectRatio: false,
            legend: {
              display: true,
            },
            tooltips: {
              enabled: true,
              mode: "index",
              intersect: false,
            },
            scales: {
              yAxes: [
                {
                  barPercentage: 1.6,
                  gridLines: {
                    drawBorder: false,
                    color: "rgba(29,140,248,0.0)",
                    zeroLineColor: "transparent",
                  },
                  ticks: {
                    padding: 0,
                    fontColor: "#8898aa",
                    fontSize: 13,
                    fontFamily: "Open Sans",
                  },
                },
              ],
              xAxes: [
                {
                  barPercentage: 1.6,
                  gridLines: {
                    drawBorder: false,
                    color: "rgba(29,140,248,0.0)",
                    zeroLineColor: "transparent",
                  },
                  ticks: {
                    padding: 10,
                    fontColor: "#8898aa",
                    fontSize: 13,
                    fontFamily: "Open Sans",
                  },
                },
              ],
            },
            layout: {
              padding: 0,
            },
          },
        }
      );

      // eslint-disable-next-line no-unused-vars
      house2HumChart = new Chart(
        document.getElementById(this.house2HumChartId).getContext("2d"),
        {
          type: "line",
          data: {
            labels: this.chartLabels,
            datasets: [
              {
                label: "1구역",
                tension: 0.4,
                borderWidth: 4,
                borderColor: "#f96332",
                pointRadius: 0,
                backgroundColor: "transparent",
                data: this.chartHouse2Hum.area1,
              },
              {
                label: "2구역",
                tension: 0.4,
                borderWidth: 4,
                borderColor: "#5e72e4",
                pointRadius: 0,
                backgroundColor: "transparent",
                data: this.chartHouse2Hum.area2,
              },
              {
                label: "3구역",
                tension: 0.4,
                borderWidth: 4,
                borderColor: "rgba(180,180,18,0.75)",
                pointRadius: 0,
                backgroundColor: "transparent",
                data: this.chartHouse2Hum.area3,
              },
            ],
          },
          options: {
            responsive: true,
            maintainAspectRatio: false,
            legend: {
              display: true,
            },
            tooltips: {
              enabled: true,
              mode: "index",
              intersect: false,
            },
            scales: {
              yAxes: [
                {
                  barPercentage: 1.6,
                  gridLines: {
                    drawBorder: false,
                    color: "rgba(29,140,248,0.0)",
                    zeroLineColor: "transparent",
                  },
                  ticks: {
                    padding: 0,
                    fontColor: "#8898aa",
                    fontSize: 13,
                    fontFamily: "Open Sans",
                  },
                },
              ],
              xAxes: [
                {
                  barPercentage: 1.6,
                  gridLines: {
                    drawBorder: false,
                    color: "rgba(29,140,248,0.0)",
                    zeroLineColor: "transparent",
                  },
                  ticks: {
                    padding: 10,
                    fontColor: "#8898aa",
                    fontSize: 13,
                    fontFamily: "Open Sans",
                  },
                },
              ],
            },
            layout: {
              padding: 0,
            },
          },
        }
      );

      // eslint-disable-next-line no-unused-vars
      house3TempChart = new Chart(
        document.getElementById(this.house3TempChartId).getContext("2d"),
        {
          type: "line",
          data: {
            labels: this.chartLabels,
            datasets: [
              {
                label: "1구역",
                tension: 0.4,
                borderWidth: 4,
                borderColor: "#f96332",
                pointRadius: 0,
                backgroundColor: "transparent",
                data: this.chartHouse3Temp.area1,
              },
              {
                label: "2구역",
                tension: 0.4,
                borderWidth: 4,
                borderColor: "#5e72e4",
                pointRadius: 0,
                backgroundColor: "transparent",
                data: this.chartHouse3Temp.area2,
              },
              {
                label: "3구역",
                tension: 0.4,
                borderWidth: 4,
                borderColor: "rgba(180,180,18,0.75)",
                pointRadius: 0,
                backgroundColor: "transparent",
                data: this.chartHouse3Temp.area3,
              },
            ],
          },
          options: {
            responsive: true,
            maintainAspectRatio: false,
            legend: {
              display: true,
            },
            tooltips: {
              enabled: true,
              mode: "index",
              intersect: false,
            },
            scales: {
              yAxes: [
                {
                  barPercentage: 1.6,
                  gridLines: {
                    drawBorder: false,
                    color: "rgba(29,140,248,0.0)",
                    zeroLineColor: "transparent",
                  },
                  ticks: {
                    padding: 0,
                    fontColor: "#8898aa",
                    fontSize: 13,
                    fontFamily: "Open Sans",
                  },
                },
              ],
              xAxes: [
                {
                  barPercentage: 1.6,
                  gridLines: {
                    drawBorder: false,
                    color: "rgba(29,140,248,0.0)",
                    zeroLineColor: "transparent",
                  },
                  ticks: {
                    padding: 10,
                    fontColor: "#8898aa",
                    fontSize: 13,
                    fontFamily: "Open Sans",
                  },
                },
              ],
            },
            layout: {
              padding: 0,
            },
          },
        }
      );

      // eslint-disable-next-line no-unused-vars
      house3HumChart = new Chart(
        document.getElementById(this.house3HumChartId).getContext("2d"),
        {
          type: "line",
          data: {
            labels: this.chartLabels,
            datasets: [
              {
                label: "1구역",
                tension: 0.4,
                borderWidth: 4,
                borderColor: "#f96332",
                pointRadius: 0,
                backgroundColor: "transparent",
                data: this.chartHouse3Hum.area1,
              },
              {
                label: "2구역",
                tension: 0.4,
                borderWidth: 4,
                borderColor: "#5e72e4",
                pointRadius: 0,
                backgroundColor: "transparent",
                data: this.chartHouse3Hum.area2,
              },
              {
                label: "3구역",
                tension: 0.4,
                borderWidth: 4,
                borderColor: "rgba(180,180,18,0.75)",
                pointRadius: 0,
                backgroundColor: "transparent",
                data: this.chartHouse3Hum.area3,
              },
            ],
          },
          options: {
            responsive: true,
            maintainAspectRatio: false,
            legend: {
              display: true,
            },
            tooltips: {
              enabled: true,
              mode: "index",
              intersect: false,
            },
            scales: {
              yAxes: [
                {
                  barPercentage: 1.6,
                  gridLines: {
                    drawBorder: false,
                    color: "rgba(29,140,248,0.0)",
                    zeroLineColor: "transparent",
                  },
                  ticks: {
                    padding: 0,
                    fontColor: "#8898aa",
                    fontSize: 13,
                    fontFamily: "Open Sans",
                  },
                },
              ],
              xAxes: [
                {
                  barPercentage: 1.6,
                  gridLines: {
                    drawBorder: false,
                    color: "rgba(29,140,248,0.0)",
                    zeroLineColor: "transparent",
                  },
                  ticks: {
                    padding: 10,
                    fontColor: "#8898aa",
                    fontSize: 13,
                    fontFamily: "Open Sans",
                  },
                },
              ],
            },
            layout: {
              padding: 0,
            },
          },
        }
      );

      // eslint-disable-next-line no-unused-vars
      houseTempChart2 = new Chart(
        document.getElementById(this.houseTempChart2Id).getContext("2d"),
        {
          type: "line",
          data: {
            labels: this.chartLabels,
            datasets: [
              {
                label: "A구역",
                tension: 0.4,
                borderWidth: 4,
                borderColor: "#f96332",
                pointRadius: 0,
                backgroundColor: "transparent",
                data: this.chart2HouseTemp.areaA,
              },
              {
                label: "B1구역",
                tension: 0.4,
                borderWidth: 4,
                borderColor: "#5e72e4",
                pointRadius: 0,
                backgroundColor: "transparent",
                data: this.chart2HouseTemp.areaB1,
              },
              {
                label: "B2구역",
                tension: 0.4,
                borderWidth: 4,
                borderColor: "#e5274e",
                pointRadius: 0,
                backgroundColor: "transparent",
                data: this.chart2HouseTemp.areaB2,
              },
              {
                label: "C구역",
                tension: 0.4,
                borderWidth: 4,
                borderColor: "rgba(180,180,18,0.75)",
                pointRadius: 0,
                backgroundColor: "transparent",
                data: this.chart2HouseTemp.areaC,
              },
            ],
          },
          options: {
            responsive: true,
            maintainAspectRatio: false,
            legend: {
              display: true,
            },
            tooltips: {
              enabled: true,
              mode: "index",
              intersect: false,
            },
            scales: {
              yAxes: [
                {
                  barPercentage: 1.6,
                  gridLines: {
                    drawBorder: false,
                    color: "rgba(29,140,248,0.0)",
                    zeroLineColor: "transparent",
                  },
                  ticks: {
                    padding: 0,
                    fontColor: "#8898aa",
                    fontSize: 13,
                    fontFamily: "Open Sans",
                  },
                },
              ],
              xAxes: [
                {
                  barPercentage: 1.6,
                  gridLines: {
                    drawBorder: false,
                    color: "rgba(29,140,248,0.0)",
                    zeroLineColor: "transparent",
                  },
                  ticks: {
                    padding: 10,
                    fontColor: "#8898aa",
                    fontSize: 13,
                    fontFamily: "Open Sans",
                  },
                },
              ],
            },
            layout: {
              padding: 0,
            },
          },
        }
      );

      // eslint-disable-next-line no-unused-vars
      houseHumChart2 = new Chart(
        document.getElementById(this.houseHumChart2Id).getContext("2d"),
        {
          type: "line",
          data: {
            labels: this.chartLabels,
            datasets: [
              {
                label: "A구역",
                tension: 0.4,
                borderWidth: 4,
                borderColor: "#f96332",
                pointRadius: 0,
                backgroundColor: "transparent",
                data: this.chart2HouseHum.areaA,
              },
              {
                label: "B1구역",
                tension: 0.4,
                borderWidth: 4,
                borderColor: "#5e72e4",
                pointRadius: 0,
                backgroundColor: "transparent",
                data: this.chart2HouseHum.areaB1,
              },
              {
                label: "B2구역",
                tension: 0.4,
                borderWidth: 4,
                borderColor: "#e5274e",
                pointRadius: 0,
                backgroundColor: "transparent",
                data: this.chart2HouseHum.areaB2,
              },
              {
                label: "C구역",
                tension: 0.4,
                borderWidth: 4,
                borderColor: "rgba(180,180,18,0.75)",
                pointRadius: 0,
                backgroundColor: "transparent",
                data: this.chart2HouseHum.areaC,
              },
            ],
          },
          options: {
            responsive: true,
            maintainAspectRatio: false,
            legend: {
              display: true,
            },
            tooltips: {
              enabled: true,
              mode: "index",
              intersect: false,
            },
            scales: {
              yAxes: [
                {
                  barPercentage: 1.6,
                  gridLines: {
                    drawBorder: false,
                    color: "rgba(29,140,248,0.0)",
                    zeroLineColor: "transparent",
                  },
                  ticks: {
                    padding: 0,
                    fontColor: "#8898aa",
                    fontSize: 13,
                    fontFamily: "Open Sans",
                  },
                },
              ],
              xAxes: [
                {
                  barPercentage: 1.6,
                  gridLines: {
                    drawBorder: false,
                    color: "rgba(29,140,248,0.0)",
                    zeroLineColor: "transparent",
                  },
                  ticks: {
                    padding: 10,
                    fontColor: "#8898aa",
                    fontSize: 13,
                    fontFamily: "Open Sans",
                  },
                },
              ],
            },
            layout: {
              padding: 0,
            },
          },
        }
      );

      // eslint-disable-next-line no-unused-vars
      houseCo2LuxChart2 = new Chart(
        document.getElementById(this.houseCo2LuxChart2Id).getContext("2d"),
        {
          type: "line",
          data: {
            labels: this.chartLabels,
            datasets: [
              {
                label: "Co2",
                tension: 0.4,
                borderWidth: 4,
                borderColor: "#f96332",
                pointRadius: 0,
                backgroundColor: "transparent",
                yAxisId: "y-axis-0",
                data: this.chart2HoueCo2Lux.co2,
              },
              {
                label: "조도",
                tension: 0.4,
                borderWidth: 4,
                borderColor: "#5e72e4",
                pointRadius: 0,
                backgroundColor: "transparent",
                yAxisId: "y-axis-1",
                data: this.chart2HoueCo2Lux.lux,
              },
            ],
          },
          options: {
            responsive: true,
            maintainAspectRatio: false,
            legend: {
              display: true,
            },
            tooltips: {
              enabled: true,
              mode: "index",
              intersect: false,
            },
            scales: {
              yAxes: [
                {
                  id: "y-axis-0",
                  position: "left",
                  barPercentage: 1.6,
                  gridLines: {
                    drawBorder: false,
                    color: "rgba(29,140,248,0.0)",
                    zeroLineColor: "transparent",
                  },
                  ticks: {
                    padding: 0,
                    fontColor: "#8898aa",
                    fontSize: 13,
                    fontFamily: "Open Sans",
                  },
                },
                {
                  id: "y-axis-1",
                  position: "right",
                  barPercentage: 1.6,
                  gridLines: {
                    drawBorder: false,
                    color: "rgba(29,140,248,0.0)",
                    zeroLineColor: "transparent",
                  },
                  ticks: {
                    padding: 0,
                    fontColor: "#8898aa",
                    fontSize: 13,
                    fontFamily: "Open Sans",
                  },
                },
              ],
              xAxes: [
                {
                  barPercentage: 1.6,
                  gridLines: {
                    drawBorder: false,
                    color: "rgba(29,140,248,0.0)",
                    zeroLineColor: "transparent",
                  },
                  ticks: {
                    padding: 10,
                    fontColor: "#8898aa",
                    fontSize: 13,
                    fontFamily: "Open Sans",
                  },
                },
              ],
            },
            layout: {
              padding: 0,
            },
          },
        }
      );
    },
  },
};
</script>
<style></style>
