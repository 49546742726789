<template>
  <div>
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
    </base-header>

    <div class="container-fluid mt--7">
      <div class="row mt-5">
        <div class="col-xl-12 mb-5 mb-xl-0">
          <div class="card">
            <div class="card-header border-0">
              <div class="row align-items-center">
                <div class="col">
                  <h3 class="mb-0">펌프 / 컴프레샤 / 회수벨브</h3>
                </div>
              </div>
            </div>

            <div class="table-responsive">
              <table class="table text-center align-middle">
                <thead class="thead-light">
                  <tr>
                    <th>분무펌프</th>
                    <th>컴프레샤벨브</th>
                    <th>회수벨브1</th>
                    <th>회수벨브2</th>
                  </tr>
                </thead>
                <tbody class="list">
                  <tr>
                    <th>{{ powerRoom.SprayPumpRun ? "가동중" : "정지" }}</th>
                    <th>{{ powerRoom.CompValve ? "가동중" : "정지" }}</th>
                    <th>{{ powerRoom.Over1Valve ? "가동중" : "정지" }}</th>
                    <th>{{ powerRoom.Over2Valve ? "가동중" : "정지" }}</th>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-5">
        <div class="col-xl-12 mb-5 mb-xl-0">
          <div class="card">
            <div class="card-header border-0">
              <div class="row align-items-center">
                <div class="col">
                  <h3 class="mb-0">자동화연동온실</h3>
                </div>
              </div>
            </div>

            <div class="table-responsive">
              <table class="table text-center align-middle">
                <thead class="thead-light">
                  <tr>
                    <th colspan="2">1동</th>
                    <th colspan="2">2동</th>
                    <th colspan="2">3동</th>
                  </tr>
                  <tr>
                    <th>공급벨브</th>
                    <th>구동모터</th>
                    <th>공급벨브</th>
                    <th>구동모터</th>
                    <th>공급벨브</th>
                    <th>구동모터</th>
                  </tr>
                </thead>
                <tbody class="list">
                  <tr>
                    <th>{{ powerRoom.House1Value ? "가동중" : "정지" }}</th>
                    <th>{{ powerRoom.House1Move ? "가동중" : "정지" }}</th>
                    <th>{{ powerRoom.House2Value ? "가동중" : "정지" }}</th>
                    <th>{{ powerRoom.House2Move ? "가동중" : "정지" }}</th>
                    <th>{{ powerRoom.House3Value ? "가동중" : "정지" }}</th>
                    <th>{{ powerRoom.House3Move ? "가동중" : "정지" }}</th>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-5">
        <div class="col-xl-12 mb-5 mb-xl-0">
          <div class="card">
            <div class="card-header border-0">
              <div class="row align-items-center">
                <div class="col">
                  <h3 class="mb-0">야외생육시설</h3>
                </div>
              </div>
            </div>

            <div class="table-responsive">
              <table class="table text-center align-middle">
                <thead class="thead-light">
                  <tr>
                    <th colspan="2">1동</th>
                    <th colspan="2">2동</th>
                    <th colspan="2">3동</th>
                  </tr>
                  <tr>
                    <th>공급벨브</th>
                    <th>구동모터</th>
                    <th>공급벨브</th>
                    <th>구동모터</th>
                    <th>공급벨브</th>
                    <th>구동모터</th>
                  </tr>
                </thead>
                <tbody class="list">
                  <tr>
                    <th>{{ powerRoom.Out1Value ? "가동중" : "정지" }}</th>
                    <th>{{ powerRoom.Out1Move ? "가동중" : "정지" }}</th>
                    <th>{{ powerRoom.Out2Value ? "가동중" : "정지" }}</th>
                    <th>{{ powerRoom.Out2Move ? "가동중" : "정지" }}</th>
                    <th>{{ powerRoom.Out3Value ? "가동중" : "정지" }}</th>
                    <th>{{ powerRoom.Out3Move ? "가동중" : "정지" }}</th>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DataService from "@/services/data.service";

export default {
  components: {},
  data() {
    return {
      powerRoom: {},
    };
  },
  mounted() {
    DataService.getPowerRoom().then(
      (response) => {
        this.powerRoom = response.data.powerRoom;
      },
      (error) => {
        console.log(error);
      }
    );
  },
};
</script>

<style></style>
