<template>
  <div>
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
    </base-header>

    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col-xl-12 order-xl-1">
          <card shadow type="secondary">
            <template v-slot:header>
              <div class="bg-white border-0">
                <div class="row align-items-center">
                  <div class="col-8">
                    <h3 class="mb-0">사용자 정보</h3>
                  </div>
                  <div class="col-4 text-right">
                    <a
                      href="#!"
                      class="btn btn-sm btn-primary"
                      @click.prevent="registerUser"
                      >등록</a
                    >
                  </div>
                </div>
              </div>
            </template>

            <form>
              <h6 class="heading-small text-muted mb-4">사용자</h6>
              <div class="pl-lg-4">
                <div class="row">
                  <div class="col-lg-6">
                    <base-input
                      alternative=""
                      label="아이디"
                      placeholder="아이디"
                      input-classes="form-control-alternative"
                      v-model="user.id"
                    />
                  </div>
                  <div class="col-lg-6">
                    <base-input
                      alternative=""
                      label="이름"
                      placeholder="이름"
                      input-classes="form-control-alternative"
                      v-model="user.name"
                    />
                  </div>
                </div>
              </div>
              <h6 class="heading-small text-muted mb-4">비밀번호</h6>
              <div class="pl-lg-4">
                <div class="row">
                  <div class="col-lg-6">
                    <base-password
                      alternative=""
                      label="비밀번호"
                      placeholder=""
                      input-classes="form-control-alternative"
                      v-model="user.password"
                    />
                  </div>
                  <div class="col-lg-6">
                    <base-password
                      alternative=""
                      label="비밀번호 확인"
                      placeholder=""
                      input-classes="form-control-alternative"
                      v-model="user.passwordCheck"
                    />
                  </div>
                </div>
              </div>
            </form>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import DataService from "@/services/data.service";

export default {
  name: "user-profile",
  data() {
    return {
      user: {
        name: "",
        id: "",
        password: "",
        passwordCheck: "",
      },
    };
  },
  methods: {
    registerUser() {
      DataService.postUser(this.user).then(
        () => {
          this.$router.push("/userList");
        },
        (error) => {
          console.log(error);
        }
      );
    },
  },
};
</script>
<style></style>
