<template>
  <div>
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
    </base-header>

    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col">
          <div class="card shadow">
            <div class="card-header border-0">
              <div class="row align-items-center">
                <div class="col">
                  <h3 class="mb-0">사용자목록</h3>
                </div>
                <div class="col-4 text-right">
                  <router-link to="/userRegister" class="btn btn-sm btn-primary"
                    >추가</router-link
                  >
                </div>
              </div>
            </div>

            <div class="table-responsive">
              <base-table
                class="table align-items-center table-flush"
                thead-classes="thead-light"
                tbody-classes="list"
                :data="userList"
              >
                <template v-slot:columns>
                  <th width="25%">아이디</th>
                  <th width="25%">이름</th>
                  <th width="40%">최종 접속 일시</th>
                  <th width="10%">삭제</th>
                </template>

                <template v-slot:default="row">
                  <th scope="row">
                    <div class="media align-items-center">
                      <div class="media-body">
                        <span class="name mb-0 text-sm">{{ row.item.id }}</span>
                      </div>
                    </div>
                  </th>
                  <td class="budget">
                    {{ row.item.name }}
                  </td>
                  <td>
                    <span class="status">{{ row.item.LoginDate }}</span>
                  </td>
                  <td>
                    <span
                      class="status"
                      @click.prevent="deleteUser(row.item.id)"
                      ><base-button type="danger">삭제</base-button></span
                    >
                  </td>
                </template>
              </base-table>
            </div>

            <div class="card-footer d-flex justify-content-end">
              <base-pagination
                v-bind:pageCount="totalPage"
                v-bind:value="page"
                v-on:input="getUserList"
              ></base-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import DataService from "@/services/data.service";

export default {
  data() {
    return {
      userList: [],
      page: 1,
      pageSize: 10,
      totalPage: 1,
    };
  },
  methods: {
    getUserList(page) {
      DataService.getUserList(page, this.pageSize).then(
        (response) => {
          this.userList = response.data.userList;
          this.page = response.data.page;
          this.totalPage = response.data.totalPage;
        },
        (error) => {
          console.log(error);
        }
      );
    },
    deleteUser(id) {
      console.log("delete");
      if (confirm(id + " 사용자를 삭제하시겠습니까?")) {
        DataService.deleteUser(id).then(
          () => {
            this.getUserList(this.page);
          },
          (error) => {
            console.log(error);
          }
        );
      }
    },
  },
  mounted() {
    this.getUserList(this.page);
  },
};
</script>
<style></style>
