<template>
  <div>
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
      <div class="row">
        <div class="col-xl-2 col-lg-4">
          <stats-card
            title="1동 온도"
            type="gradient-red"
            icon="fa fa-thermometer-half"
            class="mb-4 mb-xl-0"
            v-bind:sub-title="outdoor.OutdTemp"
          >
          </stats-card>
        </div>
        <div class="col-xl-2 col-lg-4">
          <stats-card
            title="1동 습도"
            type="gradient-info"
            v-bind:sub-title="outdoor.OutdHum"
            icon="fa fa-tint"
            class="mb-4 mb-xl-0"
          >
          </stats-card>
        </div>
        <div class="col-xl-2 col-lg-4">
          <stats-card
            title="2동 온도"
            type="gradient-red"
            icon="fa fa-thermometer-half"
            class="mb-4 mb-xl-0"
            v-bind:sub-title="outdoor.Out1Temp"
          >
          </stats-card>
        </div>
        <div class="col-xl-2 col-lg-4">
          <stats-card
            title="2동 습도"
            type="gradient-info"
            v-bind:sub-title="outdoor.Out1Hum"
            icon="fa fa-tint"
            class="mb-4 mb-xl-0"
          >
          </stats-card>
        </div>
        <div class="col-xl-2 col-lg-4">
          <stats-card
            title="3동 온도"
            type="gradient-red"
            icon="fa fa-thermometer-half"
            class="mb-4 mb-xl-0"
            v-bind:sub-title="outdoor.Out2Temp"
          >
          </stats-card>
        </div>
        <div class="col-xl-2 col-lg-4">
          <stats-card
            title="3동 습도"
            type="gradient-info"
            v-bind:sub-title="outdoor.Out2Hum"
            icon="fa fa-tint"
            class="mb-4 mb-xl-0"
          >
          </stats-card>
        </div>
      </div>
    </base-header>

    <div class="container-fluid mt--7">
      <!--Charts-->
      <div class="row">
        <div class="col-xl-6 mb-5 mb-xl-0">
          <card header-classes="bg-transparent">
            <template v-slot:header>
              <div class="row align-items-center">
                <div class="col">
                  <h5 class="h3 mb-0">지온 트렌드</h5>
                </div>
              </div>
            </template>
            <div class="chart-area">
              <canvas :height="350" :id="tempChartID"></canvas>
            </div>
          </card>
        </div>

        <div class="col-xl-6">
          <card header-classes="bg-transparent">
            <template v-slot:header>
              <div class="row align-items-center">
                <div class="col">
                  <h5 class="h3 mb-0">지습 트렌드</h5>
                </div>
              </div>
            </template>
            <div class="chart-area">
              <canvas :height="350" :id="humidChartID"></canvas>
            </div>
          </card>
        </div>
      </div>
      <!-- End charts-->

      <!--Tables-->
      <div class="row mt-5">
        <div class="col-xl-12 mb-5 mb-xl-0">
          <div class="card">
            <div class="card-header border-0">
              <div class="row align-items-center">
                <div class="col">
                  <h3 class="mb-0">지온지습</h3>
                </div>
              </div>
            </div>

            <div class="table-responsive">
              <table class="table text-center">
                <thead class="thead-light">
                  <tr>
                    <th>동</th>
                    <th>지온</th>
                    <th>지습</th>
                  </tr>
                </thead>
                <tbody class="list">
                  <tr>
                    <td>1</td>
                    <td>{{ outdoor.OutGrTemp_11 }}℃</td>
                    <td>{{ outdoor.OutGrHum_11 }}%</td>
                  </tr>
                  <tr>
                    <td>2</td>
                    <td>{{ outdoor.Out1GrTemp }}℃</td>
                    <td>{{ outdoor.Out1GrHum }}%</td>
                  </tr>
                  <tr>
                    <td>3</td>
                    <td>{{ outdoor.Out2GrTemp }}℃</td>
                    <td>{{ outdoor.Out2GrHum }}%</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-5">
        <div class="col-xl-12 mb-5 mb-xl-0">
          <div class="card">
            <div class="card-header border-0">
              <div class="row align-items-center">
                <div class="col">
                  <h3 class="mb-0">자주식살수기 운전상태</h3>
                </div>
              </div>
            </div>

            <div class="table-responsive">
              <table class="table text-center">
                <thead class="thead-light">
                   <tr>
                    <th>1동</th>
                    <th>2동</th>
                    <th>3동</th>
                  </tr>
                </thead>
                <tbody class="list">
                  <tr>
                    <th>
                      {{ powerRoom.Out1Service ? "가동" : "정지" }}
                    </th>
                    <th>
                      {{ powerRoom.Out2Service ? "가동" : "정지" }}
                    </th>
                    <th>
                      {{ powerRoom.Out3Service ? "가동" : "정지" }}
                    </th>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-5">
        <div class="col-xl-12 mb-5 mb-xl-0">
          <div class="card">
            <div class="card-header border-0">
              <div class="row align-items-center">
                <div class="col">
                  <h3 class="mb-0">수평커튼 운전상태</h3>
                </div>
              </div>
            </div>

            <div class="table-responsive">
              <table class="table text-center">
                <thead class="thead-light">
                  <tr>
                    <th rowspan="2">1동</th>
                    <th colspan="2">2동</th>
                    <th colspan="2">3동</th>
                  </tr>
                  <tr>
                    <th>상</th>
                    <th>하</th>
                    <th>상</th>
                    <th>하</th>
                  </tr>
                </thead>
                <tbody class="list">
                  <tr>
                    <th v-if="powerRoom.OutCurOpen || powerRoom.OutCurClose">
                      {{ powerRoom.OutCurOpen ? "열림" : "닫힘" }}
                    </th>
                    <th v-else>정지</th>
                    <th v-if="powerRoom.Out11Open || powerRoom.Out11Close">
                      {{ powerRoom.Out11Open ? "열림" : "닫힘" }}
                    </th>
                    <th v-else>정지</th>
                    <th v-if="powerRoom.Out12Open || powerRoom.Out12Close">
                      {{ powerRoom.Out12Open ? "열림" : "닫힘" }}
                    </th>
                    <th v-else>정지</th>
                    <th v-if="powerRoom.Out21Open || powerRoom.Out21Close">
                      {{ powerRoom.Out21Open ? "열림" : "닫힘" }}
                    </th>
                    <th v-else>정지</th>
                    <th v-if="powerRoom.Out22Open || powerRoom.Out22Close">
                      {{ powerRoom.Out22Open ? "열림" : "닫힘" }}
                    </th>
                    <th v-else>정지</th>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <!--End tables-->
    </div>
  </div>
</template>
<script>
import Chart from "chart.js";
import DataService from "../services/data.service";

let tempChart;
let humChart;

export default {
  components: {},
  data() {
    return {
      outdoor: {},
      powerRoom: {},
      chart: [],
      chartLabels: [],
      chartTemp1: [],
      chartTemp2: [],
      chartTemp3: [],
      chartHumid1: [],
      chartHumid2: [],
      chartHumid3: [],
      tempChartID: "tempChart",
      humidChartID: "humidChart",
    };
  },
  methods: {
    initChart() {
      // eslint-disable-next-line no-unused-vars
      tempChart = new Chart(
        document.getElementById(this.tempChartID).getContext("2d"),
        {
          type: "line",
          data: {
            labels: this.chartLabels,
            datasets: [
              {
                label: "1동",
                tension: 0.4,
                borderWidth: 4,
                borderColor: "#f96332",
                pointRadius: 0,
                backgroundColor: "transparent",
                data: this.chartTemp1,
              },
              {
                label: "2동",
                tension: 0.4,
                borderWidth: 4,
                borderColor: "#f97e32",
                pointRadius: 0,
                backgroundColor: "transparent",
                data: this.chartTemp2,
              },
              {
                label: "3동",
                tension: 0.4,
                borderWidth: 4,
                borderColor: "#f9ba32",
                pointRadius: 0,
                backgroundColor: "transparent",
                data: this.chartTemp3,
              },
            ],
          },
          options: {
            responsive: true,
            maintainAspectRatio: false,
            legend: {
              display: true,
            },
            tooltips: {
              enabled: true,
              mode: "index",
              intersect: false,
            },
            scales: {
              yAxes: [
                {
                  barPercentage: 1.6,
                  gridLines: {
                    drawBorder: false,
                    color: "rgba(29,140,248,0.0)",
                    zeroLineColor: "transparent",
                  },
                  ticks: {
                    padding: 0,
                    fontColor: "#8898aa",
                    fontSize: 13,
                    fontFamily: "Open Sans",
                  },
                },
              ],
              xAxes: [
                {
                  barPercentage: 1.6,
                  gridLines: {
                    drawBorder: false,
                    color: "rgba(29,140,248,0.0)",
                    zeroLineColor: "transparent",
                  },
                  ticks: {
                    padding: 10,
                    fontColor: "#8898aa",
                    fontSize: 13,
                    fontFamily: "Open Sans",
                  },
                },
              ],
            },
            layout: {
              padding: 0,
            },
          },
        }
      );

      // eslint-disable-next-line no-unused-vars
      humChart = new Chart(
        document.getElementById(this.humidChartID).getContext("2d"),
        {
          type: "line",
          data: {
            labels: this.chartLabels,
            datasets: [
              {
                label: "1동",
                tension: 0.4,
                borderWidth: 4,
                borderColor: "#5e72e4",
                pointRadius: 0,
                backgroundColor: "transparent",
                data: this.chartHumid1,
              },
              {
                label: "2동",
                tension: 0.4,
                borderWidth: 4,
                borderColor: "#5eb5e4",
                pointRadius: 0,
                backgroundColor: "transparent",
                data: this.chartHumid2,
              },
              {
                label: "3동",
                tension: 0.4,
                borderWidth: 4,
                borderColor: "#136054",
                pointRadius: 0,
                backgroundColor: "transparent",
                data: this.chartHumid3,
              },
            ],
          },
          options: {
            responsive: true,
            maintainAspectRatio: false,
            legend: {
              display: true,
            },
            tooltips: {
              enabled: true,
              mode: "index",
              intersect: false,
            },
            scales: {
              yAxes: [
                {
                  barPercentage: 1.6,
                  gridLines: {
                    drawBorder: false,
                    color: "rgba(29,140,248,0.0)",
                    zeroLineColor: "transparent",
                  },
                  ticks: {
                    padding: 0,
                    fontColor: "#8898aa",
                    fontSize: 13,
                    fontFamily: "Open Sans",
                  },
                },
              ],
              xAxes: [
                {
                  barPercentage: 1.6,
                  gridLines: {
                    drawBorder: false,
                    color: "rgba(29,140,248,0.0)",
                    zeroLineColor: "transparent",
                  },
                  ticks: {
                    padding: 10,
                    fontColor: "#8898aa",
                    fontSize: 13,
                    fontFamily: "Open Sans",
                  },
                },
              ],
            },
            layout: {
              padding: 0,
            },
          },
        }
      );
    },
  },
  mounted() {
    DataService.getOutdoor().then(
      (response) => {
        this.outdoor = response.data.outdoor;
        this.outdoor.OutdTemp = this.outdoor.OutdTemp.toFixed(1) + " ℃";
        this.outdoor.OutdHum = this.outdoor.OutdHum.toFixed(1) + " %";
        this.outdoor.Out1Temp = this.outdoor.Out1Temp.toFixed(1) + " ℃";
        this.outdoor.Out1Hum = this.outdoor.Out1Hum.toFixed(1) + " %";
        this.outdoor.Out2Temp = this.outdoor.Out2Temp.toFixed(1) + " ℃";
        this.outdoor.Out2Hum = this.outdoor.Out2Hum.toFixed(1) + " %";
        this.outdoor.OutGrHum_11 = this.outdoor.OutGrHum_11.toFixed(1);
        this.outdoor.OutGrTemp_11 = this.outdoor.OutGrTemp_11.toFixed(1);
        this.outdoor.Out1GrTemp = this.outdoor.Out1GrTemp.toFixed(1);
        this.outdoor.Out1GrHum = this.outdoor.Out1GrHum.toFixed(1);
        this.outdoor.Out2GrTemp = this.outdoor.Out2GrTemp.toFixed(1);
        this.outdoor.Out2GrHum = this.outdoor.Out2GrHum.toFixed(1);

        this.powerRoom = response.data.powerRoom;
        this.chart = response.data.chart;
        // 2021-12-11T18:33:00+09:00
        for (var i = this.chart.length - 1; i > -1; i--) {
          let timeString = this.chart[i].CreateDate.substr(11, 5);
          this.chartLabels.push(timeString);
          this.chartTemp1.push(this.chart[i].OutGrTemp_11.toFixed(1));
          this.chartHumid1.push(this.chart[i].OutGrHum_11.toFixed(1));
          this.chartTemp2.push(this.chart[i].Out1GrTemp.toFixed(1));
          this.chartHumid2.push(this.chart[i].Out1GrHum.toFixed(1));
          this.chartTemp3.push(this.chart[i].Out2GrTemp.toFixed(1));
          this.chartHumid3.push(this.chart[i].Out2GrHum.toFixed(1));
        }
        this.initChart();
      },
      (error) => {
        console.log(error);
      }
    );

    // ordersChart.createChart(this.humidChartID);
  },
};
</script>
<style></style>
