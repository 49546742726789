<template>
  <div>
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
    </base-header>

    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col-xl-12 order-xl-1">
          <card shadow type="secondary">
            <template v-slot:header>
              <div class="bg-white border-0">
                <div class="row align-items-center">
                  <div class="col-8">
                    <h3 class="mb-0">비밀번호 변경</h3>
                  </div>
                  <div class="col-4 text-right">
                    <a
                      href="#!"
                      class="btn btn-sm btn-primary"
                      @click.prevent="passwordChange"
                      >저장</a
                    >
                  </div>
                </div>
              </div>
            </template>

            <form>
              <h6 class="heading-small text-muted mb-4">사용자</h6>
              <div class="pl-lg-4">
                <div class="row">
                  <div class="col-lg-6">
                    <base-input
                      alternative=""
                      label="아이디"
                      placeholder="아이디"
                      input-classes="form-control-alternative"
                      disabled
                      v-model="user.id"
                    />
                  </div>
                  <div class="col-lg-6">
                    <base-input
                      alternative=""
                      label="이름"
                      placeholder="이름"
                      input-classes="form-control-alternative"
                      disabled
                      v-model="user.name"
                    />
                  </div>
                </div>
              </div>
              <h6 class="heading-small text-muted mb-4">비밀번호</h6>
              <div class="pl-lg-4">
                <div class="row">
                  <div class="col-lg-6">
                    <base-password
                      alternative=""
                      label="비밀번호"
                      placeholder=""
                      input-classes="form-control-alternative"
                      v-model="user.password"
                    />
                  </div>
                  <div class="col-lg-6">
                    <base-password
                      alternative=""
                      label="비밀번호 확인"
                      placeholder=""
                      input-classes="form-control-alternative"
                      v-model="user.passwordCheck"
                    />
                  </div>
                </div>
              </div>
            </form>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import DataService from "@/services/data.service";

export default {
  data() {
    return {
      user: {
        name: "",
        id: "",
        password: "",
        passwordCheck: "",
      },
    };
  },
  methods: {
    passwordChange() {
      if (
        this.user.password != this.user.passwordCheck ||
        this.user.password == ""
      ) {
        alert("비밀번호를 확인해주세요.");
        return;
      }
      if (this.user.password.length < 8) {
        alert("비밀번호는 8자리 이상 입력해주세요.");
        return;
      }

      DataService.patchUser(this.user).then(
        () => {
          this.$router.go(-1);
        },
        (error) => {
          console.log(error);
        }
      );
    },
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
  mounted() {
    const user = this.currentUser();
    this.user.id = user.id;
    this.user.name = user.name;
  },
};
</script>
<style></style>
