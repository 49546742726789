import axios from "axios";
import authHeader from "./auth-header";
import AuthService from "@/services/auth.service";

const API_URL = "https://bsforest.net/api/data/";

class DataService {
  setIntercepter() {
    axios.interceptors.response.use(
      function (response) {
        return response;
      },
      function (error) {
        if (error.response.status == 401) {
          AuthService.logout();
        }
        return Promise.reject(error);
      }
    );
  }

  getHouse() {
    this.setIntercepter();
    return axios.get(API_URL + "house", { headers: authHeader() });
  }

  getHouse2() {
    this.setIntercepter();
    return axios.get(API_URL + "house2", { headers: authHeader() });
  }

  getOutdoor() {
    this.setIntercepter();
    return axios.get(API_URL + "outdoor", { headers: authHeader() });
  }

  getWater() {
    this.setIntercepter();
    return axios.get(API_URL + "water", { headers: authHeader() });
  }

  getPowerRoom() {
    this.setIntercepter();
    return axios.get(API_URL + "powerRoom", { headers: authHeader() });
  }

  getTrend() {
    this.setIntercepter();
    return axios.get(API_URL + "trend", { headers: authHeader() });
  }

  getHistory(page, pagesize) {
    this.setIntercepter();
    return axios.get(
      API_URL + "history?page=" + page + "&page_size=" + pagesize,
      { headers: authHeader() }
    );
  }

  getUserList(page, pagesize) {
    this.setIntercepter();
    return axios.get(API_URL + "user?page=" + page + "&page_size=" + pagesize, {
      headers: authHeader(),
    });
  }

  postUser(user) {
    this.setIntercepter();
    return axios.post(API_URL + "user", user, { headers: authHeader() });
  }

  patchUser(user) {
    this.setIntercepter();
    return axios.patch(API_URL + "user/" + user.id, user, {
      headers: authHeader(),
    });
  }

  deleteUser(id) {
    this.setIntercepter();
    return axios.delete(API_URL + "user/" + id, { headers: authHeader() });
  }
}

export default new DataService();
