<template>
  <div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }">
    <side-bar
      :background-color="sidebarBackground"
      short-title="보성양묘사업소"
      title="보성양묘사업소"
    >
      <template v-slot:monitoring>
        <sidebar-item
          :link="{
            name: '자동화연동온실',
            icon: 'fa fa-home text-primary',
            path: '/house',
          }"
        />
        <sidebar-item
          :link="{
            name: '자동화연동온실2',
            icon: 'fa fa-home text-primary',
            path: '/house2',
          }"
        />

        <sidebar-item
          :link="{
            name: '야외생육시설',
            icon: 'fa fa-sun text-red',
            path: '/outdoor',
          }"
        />
        <sidebar-item
          :link="{
            name: '작업동',
            icon: 'fa fa-building text-yellow',
            path: '/powerroom',
          }"
        />
        <sidebar-item
          :link="{
            name: '양액설비',
            icon: 'ni ni-single-02 text-orange',
            path: '/water',
          }"
        />
      </template>

      <template v-slot:data>
        <sidebar-item
          :link="{
            name: '트렌드',
            icon: 'ni ni-chart-bar-32 text-primary',
            path: '/trend',
          }"
        />

        <sidebar-item
          :link="{
            name: '동작 이력',
            icon: 'ni ni-bullet-list-67 text-red',
            path: '/history',
          }"
        />
        <sidebar-item
          :link="{
            name: '사용자관리',
            icon: 'ni ni-circle-08 text-pink',
            path: '/userList',
          }"
        />
      </template>
    </side-bar>
    <div class="main-content" :data="sidebarBackground">
      <dashboard-navbar></dashboard-navbar>

      <div @click.prevent="toggleSidebar">
        <!-- your content here -->
        <router-view></router-view>
        <content-footer v-if="!$route.meta.hideFooter"></content-footer>
      </div>
    </div>
  </div>
</template>
<script>
import DashboardNavbar from "./DashboardNavbar.vue";
import ContentFooter from "./ContentFooter.vue";

export default {
  components: {
    DashboardNavbar,
    ContentFooter,
  },
  data() {
    return {
      sidebarBackground: "vue", //vue|blue|orange|green|red|primary
    };
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
  },
};
</script>
<style lang="scss"></style>
